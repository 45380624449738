import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { useCallback, useEffect, useMemo } from "react"

import { EmbedIntegrationStore } from "./store"

import { DataGridProTable } from "src/components/Table/DataGridPro"
import { IColumn } from "src/types/data-grid-pro"

import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"

import { DEFAULT_ACCESS_GROUP } from "src/config"

import { ListPage } from "src/components/ListPage"
import { EmbeddedIntegrationsModal } from "src/modals/embedded-integration-v2"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { IPageFilterProps } from "src/components/PageFilter"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { Repository } from "src/types/channel"
import { RowCellContainer } from "src/components/RowCellContainer"
import { IEmbedIntegrationFormItemProps } from "src/views/embedded-integrations-v2/typed"

const repository: Repository = "embed-integrations"

const View = observer(() => {
    const store = useStore(EmbedIntegrationStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            if (globalStore.session.accessGroup.id !== undefined) {
                await store.init(
                    globalStore.session.accessGroup.id,
                    advanceQuery,
                )
            } else {
                await store.init(DEFAULT_ACCESS_GROUP.id, advanceQuery)
            }
        })()

        return () => {
            store.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, globalStore.session.accessGroup, globalStore.session.segmentIds])

    const handleOpenIntegrationClick = useCallback(
        (item) => {
            globalStore.modals.open(() => (
                <EmbeddedIntegrationsModal
                    id={item.row.id}
                    segments={item.row.segments}
                />
            ))
        },
        [globalStore],
    )

    const header = useMemo(
        () => ({
            header: t`embed-integrations-view.header`,
            breadcrumbs: [t`embed-integrations-view.header`],
            createOptions: {
                onClick: () =>
                    globalStore.modals.open(() => (
                        <EmbeddedIntegrationsModal id={undefined} />
                    )),
                item: t`embed-integrations-view.header`,
            },
        }),
        [globalStore],
    )
    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )
    const filter = useMemo(
        (): IPageFilterProps => ({
            actions: (
                <>
                    <SegmentPickerButton
                        value={store.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [handleSegmentChange, store.segments],
    )

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            globalStore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            globalStore.session.dataGridSortModel,
        )
        return { sort, filter }
    }, [
        globalStore.session.dataGridSortModel,
        globalStore.session.dataGridFilterModel,
    ])

    const tableIsLoading =
        !store.embedIntegrations.meta.initialized ||
        globalStore.loading.is(EmbedIntegrationStore.LoadingKeys.loading)

    const columns: IColumn<IEmbedIntegrationFormItemProps>[] = [
        {
            field: "id",
            headerName: t`embed-integrations.id`,
            width: 100,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            sortable: false,
        },
        {
            field: "internalName",
            headerName: t`embed-integrations.internal-name`,
            width: 200,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
        },
        {
            field: "type",
            headerName: t`embed-integrations.type`,
            width: 200,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            sortable: false,
        },
        {
            field: "partner",
            headerName: t`embed-integrations.partner`,
            width: 150,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            sortable: false,
        },
        {
            field: "categories",
            headerName: t`embed-integrations.category`,
            width: 150,
            renderCell: (params) => (
                <RowCellContainer>{params.value.join(", ")}</RowCellContainer>
            ),
            type: "string",
            sortable: false,
        },
        {
            field: "segments",
            headerName: t`embed-integrations.segments`,
            width: 150,
            renderCell: (params) => (
                <RowCellContainer>
                    {`${String(
                        params.value?.length,
                    )} ${t`contact-form-forms-view.published.value`}` ?? ""}
                </RowCellContainer>
            ),
            type: "string",
            sortable: false,
        },
    ]

    return (
        <ListPage header={header} filter={filter} loading={tableIsLoading}>
            <DataGridProTable
                paginator={store.embedIntegrations}
                data={store.embedIntegrations.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                }}
                onRowClickEvent={handleOpenIntegrationClick}
                repository={repository}
                hideToolbar={true}
                loading={globalStore.loading.is(
                    EmbedIntegrationStore.LoadingKeys.loading,
                )}
            />
        </ListPage>
    )
})

export const EmbeddedIntegrationsView2 = () => (
    <StoreProvider Store={EmbedIntegrationStore}>
        <View />
    </StoreProvider>
)
