import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { FeatureConfigBrandingStore } from "./store"

import { useFeatureConfigBrandingColumns } from "./hooks/useFeatureConfigBrandingColumns"

import { DataGridProTable } from "src/components/Table/DataGridPro"
import { useStore } from "src/store/lib/useStore"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { Repository } from "src/types/channel"
import { GlobalStore } from "src/store"

import { ListPage } from "src/components/ListPage"
import { FilterModel, SortModel } from "src/types/data-grid-pro"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

import { ConfirmModal } from "src/modals/confirm"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"

import { FeatureConfigBrandingModalView } from "src/modals/feature-config-branding"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"

const REPOSITORY: Repository = "feature-configurations"

const View = observer(() => {
    const store = useStore(FeatureConfigBrandingStore)
    const globalStore = useStore(GlobalStore)
    const columns = useFeatureConfigBrandingColumns()

    useEffect(() => {
        ;(async () => await store.init(globalStore.session.accessGroupId))()
        return () => store.dispose()
    }, [store, globalStore.session.accessGroupId])

    const handleNewConfiguringItemClick = useCallback(() => {
        globalStore.modals.open(() => <FeatureConfigBrandingModalView />)
    }, [globalStore.modals])

    const header = useMemo(
        () => ({
            header: t`global.branding`,
            breadcrumbs: [
                t`feature-config-branding-view.header.branding-configurations`,
            ],
            createOptions: {
                onClick: handleNewConfiguringItemClick,
                item: t`global.branding`,
            },
        }),
        [handleNewConfiguringItemClick],
    )

    const openDetailModalHandler = useCallback(
        (item) => {
            trackModuleEvent("Feature Config Branding Items | Detailed View", {
                [MixpanelProperties.ItemID]: item.id,
                [MixpanelProperties.AccessGroupName]:
                    globalStore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    globalStore.session.accessGroup?.id,
            })
            globalStore.modals.open(() => (
                <FeatureConfigBrandingModalView id={item.id} />
            ))
        },
        [
            globalStore.modals,
            globalStore.session.accessGroup?.id,
            globalStore.session.accessGroup?.name,
        ],
    )

    const handleDeleteItem = useCallback(
        (id) => {
            globalStore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async (confirmed) => {
                            if (confirmed) {
                                await store.deleteItem(id)
                            }
                        }}
                        title={t`global.delete.are-you-sure`}
                        content={t`global.delete.do-you-want-delete`}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        },
        [globalStore.modals, store],
    )

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )

    const filter = useMemo(
        () => ({
            actions: (
                <>
                    <SegmentPickerButton
                        value={store.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [handleSegmentChange, store.segments],
    )

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                REPOSITORY,
                globalStore.session.dataGridSortModel,
            )
            await store.query({ filter: model, sort })
        },
        [store, globalStore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                REPOSITORY,
                globalStore.session.dataGridFilterModel,
            )
            await store.query({ filter, sort: model })
        },
        [store, globalStore.session.dataGridFilterModel],
    )

    return (
        <ListPage
            header={header}
            filter={filter}
            loading={!store.featureConfigBrandingItems.meta.initialized}
        >
            <DataGridProTable
                paginator={store.featureConfigBrandingItems}
                data={store.featureConfigBrandingItems.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "server",
                }}
                rowActionsRenderer={(item) => [
                    {
                        text: t`global.action.edit-item`,
                        onClick: () => openDetailModalHandler(item),
                    },
                    {
                        text: t`global.action.delete-item`,
                        destructive: true,
                        onClick: () => handleDeleteItem(item.id as number),
                    },
                ]}
                onRowClickEvent={openDetailModalHandler}
                repository={REPOSITORY}
                hideToolbar={true}
                loading={!store.featureConfigBrandingItems.meta.initialized}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const FeatureConfigBrandingView = () => (
    <StoreProvider Store={FeatureConfigBrandingStore}>
        <View />
    </StoreProvider>
)
