import { t } from "@lingui/macro"

import { variables, IAuth } from "./variables"

import { Locale } from "src/locales/locale"
import { DEFAULT_CONFIGURABLE_LANGUAGE_VALUE } from "src/modals/feature-configuration-detail/constants/configurableLanguage"

/**
 * Export all raw environment variables.
 */
export const environment = variables

export const DEFAULT_ACCESS_GROUP = {
    id: -1,
    // Lazily evaluate as `t` cannot be called a import time.
    get name() {
        return t`head.all-access-groups`
    },
}

export const DEFAULT_LANGUAGE = Locale.Swedish

export const FALLBACK_CONFIGURABLE_LANGUAGE_VALUE =
    DEFAULT_CONFIGURABLE_LANGUAGE_VALUE.split(", ")
export type { IAuth }
