import { t } from "@lingui/macro"
import { AccordionSummary, Typography, useTheme } from "@mui/material"

import { DynamicConfigurableFormFieldsStyledAccordion } from "src/components/ConfigurableDynamicFormFields/components/ConfigurableSection/style"
import { ConfigurableSectionContent } from "src/components/ConfigurableDynamicFormFields/components/ConfigurableSectionContent"
import { IConfigurableSectionProps } from "src/components/ConfigurableDynamicFormFields/types/configurable-section"
import { ChevronDown24 } from "src/components/icons/ChevronDown24"

export const ConfigurableSection = ({
    configurableFields,
    index,
}: IConfigurableSectionProps) => {
    const theme = useTheme()
    const {
        name: sectionName,
        configurable_fields: sectionConfigurableFields,
    } = configurableFields

    if (sectionName === undefined || sectionConfigurableFields === undefined) {
        return (
            <Typography>{t`content-items-modal.unable-to-render-configurable-sections`}</Typography>
        )
    }
    return (
        <DynamicConfigurableFormFieldsStyledAccordion
            data-testid="configurable-dynamic-form-fields-accordion"
            key={sectionName}
            defaultExpanded={index === 0}
            disableGutters={true}
            backgroundColor={theme.palette.grey[100]}
        >
            <AccordionSummary expandIcon={<ChevronDown24 />} id={sectionName}>
                <Typography variant="h6" color={theme.palette.common.black}>
                    {sectionName}
                </Typography>
            </AccordionSummary>
            <ConfigurableSectionContent
                sectionConfigurableFields={sectionConfigurableFields}
                sectionName={sectionName}
            />
        </DynamicConfigurableFormFieldsStyledAccordion>
    )
}
