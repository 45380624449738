import { t } from "@lingui/macro"
import { Alert, Button, Typography } from "@mui/material"
import { observer } from "mobx-react"
import { useEffect, useCallback } from "react"

import { SegmentFormStore } from "./store"
import { ISegmentFormProps } from "./type"

import { useStore } from "src/store/lib/useStore"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { Form } from "src/components/Form"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { SegmentsSettingsSection } from "src/modals/segment-form/Sections/Settings"
import { SegmentsHierarchySection } from "src/modals/segment-form/Sections/Hierarchy"
import { GlobalStore } from "src/store"
import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"

const Modal = observer((props: ISegmentFormProps) => {
    const store = useStore(SegmentFormStore)
    const globalStore = useStore(GlobalStore)
    const segmentPickerStore = useStore(SegmentPickerStore)

    useCloseConfirmationForForm(store.formFields)

    useEffect(() => {
        ;(async () => {
            props.segmentId !== undefined &&
                (await store.init(props.segmentId, props.allowedSegmentTypes))
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const success = await store.save(async () => {
                // Reload all segment data
                await segmentPickerStore?.reload()
            })
            if (success) {
                globalStore.modals.pop()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store, segmentPickerStore, globalStore.modals],
    )

    const isButtonDisabled = () =>
        globalStore.loading.is(SegmentFormStore.LoadingKeys.submit)

    return (
        <Form onSubmit={handleSubmit}>
            <>
                <ModalHeader>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isButtonDisabled()}
                        data-testid="submit-segment-form"
                    >
                        {t`global.save`}
                    </Button>
                </ModalHeader>
                {Boolean(store.formFields.error("generic")) && (
                    <Alert severity="error">
                        {store.formFields.error("generic")}
                    </Alert>
                )}
                <FormPanel
                    sections={[
                        {
                            header: t`segment-form-modal.segment-id`,
                            content: (
                                <Typography variant="body2">
                                    {props.segmentId ??
                                        store.formFields.get("segment_id") ??
                                        "-"}
                                </Typography>
                            ),
                        },
                    ]}
                />
                <SegmentsSettingsSection />
                <SegmentsHierarchySection />
            </>
        </Form>
    )
})

export const SegmentFormModal = (props: ISegmentFormProps) => (
    <StoreProvider Store={SegmentFormStore}>
        <StoreProvider Store={SegmentPickerStore}>
            <Modal {...props} />
        </StoreProvider>
    </StoreProvider>
)
