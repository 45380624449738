import { ListItem, Stack, Box, Typography } from "@mui/material"

import { ISegmentHierarchyListItemProps } from "./types"

import { SegmentTypeIcon } from "src/components/SegmentPicker/components/SegmentTypeIcon"
import { SelectionStatusChip } from "src/components/SegmentPicker/components/SelectionStatusChip"
import {
    listItemStyle,
    listItemStackStyle,
    segmentTypeIconBoxStyle,
} from "src/modals/segment-form/styles"

export const SegmentHierarchyListItem = (
    props: ISegmentHierarchyListItemProps,
) => (
    <ListItem sx={listItemStyle}>
        <Stack sx={listItemStackStyle} paddingY={1}>
            <Stack direction="row" spacing={1}>
                <Box sx={segmentTypeIconBoxStyle}>
                    <SegmentTypeIcon type={props.type} size={20} />
                </Box>
                <Stack paddingLeft={1}>
                    <Typography variant="subtitle2" noWrap>
                        {props.name}
                    </Typography>
                    <Typography variant="caption">{props.path}</Typography>
                </Stack>
            </Stack>
            <Box sx={{ marginLeft: "auto" }}>
                <SelectionStatusChip
                    selected={false}
                    hasSelectedAncestors={false}
                    tenantCount={props.tenantCount}
                />
            </Box>
        </Stack>
    </ListItem>
)
