import { IconButton } from "@mui/material"
import { observer } from "mobx-react"
import EditIcon from "@mui/icons-material/Edit"

import { useNavigate } from "react-router"

import { useCallback } from "react"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { SegmentFormModal } from "src/modals/segment-form"
import { IEditPrefixProps } from "src/components/SegmentPicker/components/SegmentItemListPrefix/types"

export const SegmentEditPrefix = observer((props: IEditPrefixProps) => {
    const globalStore = useStore(GlobalStore)

    const navigate = useNavigate()

    const handleEditClick = useCallback(() => {
        navigate(`/segments/manage-segments/${props.segment.id}`)
        globalStore.modals.open(
            () => <SegmentFormModal segmentId={props.segment.id} />,
            {
                onClose: () => navigate("/segments/manage-segments"),
            },
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <IconButton size="small" onClick={handleEditClick}>
            <EditIcon fontSize="small" />
        </IconButton>
    )
})
