import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { useStore } from "src/store/lib/useStore"
import { ChatbotConfigurationStore } from "src/modals/chatbot-configuration/store"

export const PublishingSection = observer(() => {
    const store = useStore(ChatbotConfigurationStore)

    return (
        <FormFieldSet
            header={t`chatbot-configuration-detail.publishing-section.used-for`}
        >
            <SegmentPickerField
                value={store.segmentIDs}
                accessGroupId={store.accessGroupId}
                onChange={(segments) => store.setSegmentIDs(segments)}
                disabled={store.isReadOnly}
                publishingLevel={store.publishingLevel}
            />
        </FormFieldSet>
    )
})
