import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { MetaDataSection } from "./sections/MetaDataSection"
import { PublishingSection } from "./sections/PublishingSection"
import { IFeatureConfigBrandingModalViewProps } from "./types/configBrandingProps"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { ConfigurableDynamicFormFieldsSection } from "src/components/ConfigurableDynamicFormFields"
import { ConfigurableDynamicFormFieldsStore } from "src/components/ConfigurableDynamicFormFields/store"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { useInitializer } from "src/lib/initializer"
import { FeatureConfigBrandingStore } from "src/modals/feature-config-branding/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"

const View = observer(({ id }: IFeatureConfigBrandingModalViewProps) => {
    const globalStore = useStore(GlobalStore)
    const store = useStore(FeatureConfigBrandingStore)
    const configurableDynamicFormFieldsStore = useStore(
        ConfigurableDynamicFormFieldsStore,
    )

    useCloseConfirmationForForm(
        store.form,
        configurableDynamicFormFieldsStore.form,
    )

    const isSaveButtonDisabled =
        (store.form.getIsDirty() === false &&
            configurableDynamicFormFieldsStore.form.getIsDirty() === false) ||
        store.isLoading ||
        store.isReadOnly

    const initialized = useInitializer(async () => {
        await store.init(id, globalStore.session.accessGroupId)
    }, [id, store, globalStore.session.accessGroupId])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const validationError = await store.handleSubmit({
                defaultLanguage:
                    globalStore.session.defaultConfigurableLanguage,
                validateConfigurableDynamicFields:
                    configurableDynamicFormFieldsStore.handleValidation,
                getConfigsApiPayload:
                    configurableDynamicFormFieldsStore.getConfigsApiPayload,
            })

            if (validationError && !Boolean(store.form.error("generic"))) {
                globalStore.modals.pop()
            }
        },
        [
            store,
            configurableDynamicFormFieldsStore,
            globalStore.modals,
            globalStore.session.defaultConfigurableLanguage,
        ],
    )

    if (!initialized || store.isLoading) {
        return <CenteredSpinner height="100vh" />
    }

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isSaveButtonDisabled}
                >
                    {t`global.save`}
                </Button>
            </ModalHeader>
            {Boolean(store.form.error("generic")) && (
                <Alert severity="error">{store.form.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`global.meta-data`,
                        content: <MetaDataSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`global.configuration`,
                        content: (
                            <ConfigurableDynamicFormFieldsSection
                                configDefinition={store.configDefinition}
                                configs={store.form.get("configs")}
                                isReadOnly={store.isReadOnly}
                                configType={store.form.get("config_type")}
                            />
                        ),
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`global.publishing-settings`,
                        content: <PublishingSection />,
                    },
                ]}
            />
        </Form>
    )
})

export const FeatureConfigBrandingModalView = (
    props: IFeatureConfigBrandingModalViewProps,
) => (
    <StoreProvider Store={FeatureConfigBrandingStore}>
        <StoreProvider Store={ConfigurableDynamicFormFieldsStore}>
            <View {...props} />
        </StoreProvider>
    </StoreProvider>
)
