/* eslint-disable no-useless-escape */
interface IUrlList {
    link: string
    hasVideo: boolean
}

// youtube ID regex
export const youtubeIdRegex =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
// regex to identify youtube links
export const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|playlist\?|watch\?v=|watch\?.+(?:&|&#38;);v=))([a-zA-Z0-9\-_]{11})?(?:(?:\?|&|&#38;)index=((?:\d){1,3}))?(?:(?:\?|&|&#38;)?list=([a-zA-Z\-_0-9]{34}))?(?:\S+)?/g
// regex to identify general url structure
export const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g

export const customUrlRegex =
    /^[a-zA-Z][a-zA-Z0-9+.-]*:\/\/[a-zA-Z][a-zA-Z0-9+.-]*/

// Email Validator Regex
export const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const numberRegex = /^\d+$/

export class UrlLinksHandler {
    private text = ""
    // will receive the text in advance by doing this so
    // const myConst = new UrlLinksHandler(anyText)
    constructor(text: string) {
        this.text = text
    }
    // return all youtube matches inside the text
    public getYoutubeUrl(text?: string) {
        return (text ?? this.text).match(youtubeRegex)
    }
    // return all regular url matches inside text
    public getUrlsFromText() {
        return this.text.match(urlRegex)
    }
    // clean the text from urls
    public getTextWithoutUrls() {
        let text = this.text
        this.getUrlsFromText()?.forEach((url) => {
            text = text.replaceAll(url, "")
        })
        return text
    }
    // create a list of links with urls and if has video on it or not
    public createUrlListObject(): IUrlList[] | undefined {
        return this.getUrlsFromText()?.map((url) => {
            const videoArray = this.getYoutubeUrl(url)
            const videoLink =
                (videoArray != null && videoArray[0].length > 0) || null
            return {
                link: url,
                hasVideo: !!videoLink,
            }
        })
    }
}
