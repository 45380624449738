import { useEffect } from "react"

import { FormFields } from "src/lib/form-fields"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

/**
 * Adds a confirmation condition to the top-most modal in the stack. If a user
 * tries to close a modal when the form supplied is dirty it will show a
 * confirmation popup for the user before closing the modal.
 * @param form Form to check
 */
export function useCloseConfirmationForForm<T extends Object, P extends Object>(
    form: FormFields<T>,
    form2?: FormFields<P>,
) {
    const gstore = useStore(GlobalStore)
    useEffect(() => {
        gstore.modals.addConfirmCondition(
            () => form.getIsDirty() || Boolean(form2?.getIsDirty()),
        )
    }, [gstore.modals, form, form2])
}
