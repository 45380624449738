import { t } from "@lingui/macro"
import { useMemo } from "react"

export const useGetContentTypes = () => {
    return useMemo(
        () => [
            {
                id: "module",
                label: t`navigation-items-view.columns.dropdown.module`,
                value: "module",
            },
            {
                id: "embed",
                label: t`navigation-items-view.columns.dropdown.embed`,
                value: "embed",
            },
            {
                id: "integration_connector",
                label: t`navigation-items-view.columns.dropdown.integration`,
                value: "integration_connector",
            },
            {
                id: "subscription_plan",
                label: t`navigation-items-view.columns.dropdown.subscription-plan`,
                value: "subscription_plan",
            },
            {
                id: "catalogue_product",
                label: t`navigation-items-view.columns.dropdown.catalogue-product`,
                value: "catalogue_product",
            },
            {
                id: "information_item",
                label: t`navigation-items-view.columns.dropdown.information-item`,
                value: "information_item",
            },
        ],
        [],
    )
}
