import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { useParams } from "react-router"

import { ChatbotConversationStore } from "./store"

import { ListPage } from "src/components/ListPage"
import { IPageFilterProps } from "src/components/PageFilter"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { ChatbotConversationModalView } from "src/modals/chatbot"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { Repository } from "src/types/channel"
import { FilterModel, SortModel } from "src/types/data-grid-pro"
import { useGetChatbotColumns } from "src/views/chatbot/hooks/useGetChatbotColumns"

const repository: Repository = "chatbotConversation"

const View = observer(() => {
    const store = useStore(ChatbotConversationStore)
    const gstore = useStore(GlobalStore)
    const { conversationId } = useParams()

    const tagsValueOptions = useMemo(() => {
        return (store.tags ?? []).map((tag) => ({
            label: tag.name,
            value: tag.tag_id,
        }))
    }, [store.tags])
    const columns = useGetChatbotColumns(tagsValueOptions)

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            gstore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            gstore.session.dataGridSortModel,
        )
        return { sort, filter }
    }, [gstore.session.dataGridSortModel, gstore.session.dataGridFilterModel])

    useEffect(() => {
        ;(async () => {
            await store.init(gstore.session.accessGroupId, advanceQuery)
        })()

        return () => {
            store.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, gstore.session.accessGroupId, gstore.session.segmentIds])

    useEffect(() => {
        ;(async () => {
            if (conversationId !== undefined) {
                await openDetailModalHandler(conversationId)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                gstore.session.dataGridSortModel,
            )

            await store.query({ sort, filter: model })
        },
        [store, gstore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                gstore.session.dataGridFilterModel,
            )

            await store.query({ filter, sort: model })
        },
        [store, gstore.session.dataGridFilterModel],
    )

    const openDetailModalHandler = useCallback(
        (conversationId?: string) => {
            if (conversationId !== undefined) {
                gstore.modals.open(() => (
                    <ChatbotConversationModalView
                        conversationId={conversationId}
                        allConversationIds={store.allConversationIds}
                    />
                ))
            }
        },
        [gstore.modals, store.allConversationIds],
    )

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )

    const header = useMemo(
        () => ({
            header: t`chatbot-view.header`,
            breadcrumbs: [t`navigation.chatbot`, t`chatbot-view.header`],
        }),
        [],
    )

    const filter = useMemo(
        (): IPageFilterProps => ({
            actions: (
                <>
                    <SegmentPickerButton
                        value={store.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [handleSegmentChange, store.segments],
    )

    return (
        <ListPage
            header={header}
            filter={filter}
            loading={gstore.loading.is(
                ChatbotConversationStore.LoadingKeys.init,
            )}
        >
            <DataGridProTable
                paginator={store.chatbotList}
                data={store.chatbotList.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    sorting: "server",
                    filtering: "server",
                }}
                onRowClickEvent={(item) =>
                    openDetailModalHandler(item.row.conversation_id)
                }
                repository={repository}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
                loading={gstore.loading.is(
                    ChatbotConversationStore.LoadingKeys.loading,
                )}
            />
        </ListPage>
    )
})

export const ChatbotConversation = observer(() => (
    <StoreProvider Store={ChatbotConversationStore}>
        <View />
    </StoreProvider>
))
