import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { useStore } from "src/store/lib/useStore"
import { FeatureConfigBrandingStore } from "src/modals/feature-config-branding/store"

export const PublishingSection = observer(() => {
    const store = useStore(FeatureConfigBrandingStore)

    return (
        <FormFieldSet header={t`global.publishing-section.used-for`}>
            <SegmentPickerField
                value={store.segmentIDs}
                accessGroupId={store.accessGroupId}
                onChange={(segments) => store.setSegmentIDs(segments)}
                disabled={store.isReadOnly}
            />
        </FormFieldSet>
    )
})
