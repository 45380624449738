import { Radio } from "@mui/material"
import { observer } from "mobx-react"

import { useStore } from "src/store/lib/useStore"
import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { IRadioPrefixProps } from "src/components/SegmentPicker/components/SegmentItemListPrefix/types"

export const SegmentRadioPrefix = observer(({ segment }: IRadioPrefixProps) => {
    const store = useStore(SegmentPickerStore)

    return (
        <Radio
            checked={store.hasSelectedSegment(segment)}
            onChange={() => store.handleRadioSelection(segment)}
            color="primary"
            size="small"
        />
    )
})
