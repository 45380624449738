import { SxProps, Theme } from "@mui/material"

export const listItemStyle: SxProps<Theme> = {
    background: (theme) => theme.palette.utility.appleFaded.light,
    marginBottom: "2px",
    alignItems: "center",
    borderRadius: "8px",
    border: (theme) => `2px solid ${theme.palette.utility.apple.main}`,
}

export const listItemStackStyle: SxProps<Theme> = {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    padding: 0,
}

export const segmentTypeIconBoxStyle: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
}
