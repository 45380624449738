import { styled } from "@mui/material"

export const FadedRowCellContainer = styled("div")(({ theme }) => ({
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    color: theme.palette.grey[600],
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}))
