import { t } from "@lingui/macro"
import { useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo, useEffect } from "react"

import { AutoCompleteTextField } from "src/components/AutoCompleteTextField"
import { UrlParamManager } from "src/lib/urlParamManager"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { IGenericAutoCompleteTextFieldValue } from "src/types/auto-complete-text-field"
import { configTypeItemsOptionListArray } from "src/views/content-items/constants/configTypeItemsOptionList"
import { ContentItemsStore } from "src/views/content-items/store"
import { IContentItemsMultiSelectOption } from "src/views/content-items/types/content-items"

export const ConfigTypeFilterSelector = observer(() => {
    const { palette } = useTheme()
    const globalStore = useStore(GlobalStore)
    const store = useStore(ContentItemsStore)
    const urlManager = useMemo(() => UrlParamManager.getInstance(), [])

    useEffect(() => {
        void updateConfigTypeOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateConfigTypeOptions = useCallback(async () => {
        const configTypeUrlParam = urlManager.getParamAsArray("config_type")
        urlManager.setParam("config_type", configTypeUrlParam)
        const selected: IGenericAutoCompleteTextFieldValue[] =
            configTypeItemsOptionListArray.filter((option) =>
                configTypeUrlParam.includes(String(option.id)),
            )
        await store.setConfigTypeFilterSelected(
            selected as IContentItemsMultiSelectOption[],
        )
    }, [store, urlManager])

    const handleSelectOnChange = useCallback(async (newValue) => {
        const valueToUpdate = newValue.map(
            (option: { id: string }) => option.id,
        )

        urlManager.setParam("config_type", valueToUpdate)
        await store.setConfigTypeFilterSelected(newValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AutoCompleteTextField
            limitTags={1}
            disableCloseOnSelect
            options={configTypeItemsOptionListArray}
            defaultValues={store.configTypeFilterSelectedValue}
            placeholder={t`feature-config.config-type-filter-placeholder`}
            onChange={handleSelectOnChange}
            loading={
                globalStore.loading.is(ContentItemsStore.LoadingKeys.init) ||
                globalStore.loading.is(ContentItemsStore.LoadingKeys.loading)
            }
            sx={{
                width: "300px",
                backgroundColor: palette.common.white,
            }}
        />
    )
})
