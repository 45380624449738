/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { dev_DeleteEmbedPurchaseRequest } from "../models/dev_DeleteEmbedPurchaseRequest"
import type { dev_DeleteLeaseContractTerminationRequest } from "../models/dev_DeleteLeaseContractTerminationRequest"
import type { dev_DeleteStandardImprovementOrdersRequest } from "../models/dev_DeleteStandardImprovementOrdersRequest"
import type { dev_EnableMoveInPeriodRequest } from "../models/dev_EnableMoveInPeriodRequest"
import type { dev_ResetAxaRequest } from "../models/dev_ResetAxaRequest"
import type { dev_ResetRebelRequest } from "../models/dev_ResetRebelRequest"
import type { dev_SetAdminPasswordRequest } from "../models/dev_SetAdminPasswordRequest"
import type { dev_UnregisterTenantRequest } from "../models/dev_UnregisterTenantRequest"
import type { dev_UnsignHedvigRequest } from "../models/dev_UnsignHedvigRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class DevService {
    /**
     * Delete embed integration purchase
     * Delete embed integration purchase
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminDevDeleteEmbedPurchase({
        request,
    }: {
        /** Request body delete embed purchase **/
        request: dev_DeleteEmbedPurchaseRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/dev/delete-embed-purchase",
            body: request,
        })
    }

    /**
     * Delete lease contract termination
     * Delete lease contract termination
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminDevDeleteLeaseContractTermination({
        request,
    }: {
        /** Request body delete lease contract termination **/
        request: dev_DeleteLeaseContractTerminationRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/dev/delete-lease-contract-termination",
            body: request,
        })
    }

    /**
     * Delete standard improvement orders
     * Delete standard improvement orders
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminDevDeleteStandardImprovementOrder({
        request,
    }: {
        /** Request body delete standard improvement orders **/
        request: dev_DeleteStandardImprovementOrdersRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/dev/delete-standard-improvement-order",
            body: request,
        })
    }

    /**
     * Enable move in period
     * Move the lease contract start within a month
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminDevEnableMoveIn({
        request,
    }: {
        /** Request body enable move in **/
        request: dev_EnableMoveInPeriodRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/dev/enable-move-in",
            body: request,
        })
    }

    /**
     * Reset Axa
     * Resets Axa for tenant (expires old subscriptions)
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminDevResetAxa({
        request,
    }: {
        /** Request body reset Axa **/
        request: dev_ResetAxaRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/dev/reset-axa",
            body: request,
        })
    }

    /**
     * Reset Rebel
     * Resets Rebel for tenant (expires old subscriptions)
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminDevResetRebel({
        request,
    }: {
        /** Request body reset Rebel **/
        request: dev_ResetRebelRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/dev/reset-rebel",
            body: request,
        })
    }

    /**
     * Set admin's password
     * Set admin's password
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminDevSetAdminPassword({
        request,
    }: {
        /** Request body **/
        request: dev_SetAdminPasswordRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/dev/set-admin-password",
            body: request,
        })
    }

    /**
     * Unregister tenant
     * Unregister tenant
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminDevUnregisterTenant({
        request,
    }: {
        /** Request body unregister tenant **/
        request: dev_UnregisterTenantRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/dev/unregister-tenant",
            body: request,
        })
    }

    /**
     * Delete standard improvement orders
     * Delete standard improvement orders
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminDevUnsignHedvig({
        request,
    }: {
        /** Request body delete standard improvement orders **/
        request: dev_UnsignHedvigRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/dev/unsign-hedvig",
            body: request,
        })
    }
}
