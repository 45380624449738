import { ConfigurableDynamicFormFieldsUnhandledElementUi } from "src/components/ConfigurableDynamicFormFields/components/UnhandledUIElement"
import { ConfigurableDynamicFormFieldsAssetLibraryPicker } from "src/components/ConfigurableDynamicFormFields/formFields/asset-picker"
import { ConfigurableDynamicFormFieldsTextList } from "src/components/ConfigurableDynamicFormFields/formFields/text-list"
import { ConfigurableDynamicFormFieldsTextField } from "src/components/ConfigurableDynamicFormFields/formFields/text-field"
import { ConfigurableDynamicFormFieldsColorPicker } from "src/components/ConfigurableDynamicFormFields/formFields/color-picker"
import { IConfigurableDynamicFormFieldsProps } from "src/components/ConfigurableDynamicFormFields/types/form-field"

export const DynamicConfigurableFormField = ({
    sectionName,
    field,
}: IConfigurableDynamicFormFieldsProps) => {
    switch (field.type) {
        case "text":
        case "integer":
        case "textarea":
            return (
                <ConfigurableDynamicFormFieldsTextField
                    field={field}
                    sectionName={sectionName}
                />
            )
        case "icon":
            return (
                <ConfigurableDynamicFormFieldsAssetLibraryPicker
                    activeAssetType="icons"
                    sectionName={sectionName}
                    field={field}
                />
            )
        case "image":
            return (
                <ConfigurableDynamicFormFieldsAssetLibraryPicker
                    activeAssetType="images"
                    sectionName={sectionName}
                    field={field}
                />
            )
        case "text_list":
            return (
                <ConfigurableDynamicFormFieldsTextList
                    field={field}
                    sectionName={sectionName}
                />
            )
        case "color":
            return (
                <ConfigurableDynamicFormFieldsColorPicker
                    field={field}
                    sectionName={sectionName}
                />
            )
        default:
            // TODO: handle file type, but currently not being used
            return <ConfigurableDynamicFormFieldsUnhandledElementUi />
    }
}
