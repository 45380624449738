import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { AccessViewStore } from "./store"

import { ListPage } from "src/components/ListPage"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { IColumn } from "src/types/data-grid-pro"
import { Repository } from "src/types/channel"
import { IAccessItem } from "src/views/access/type"
import { AccessDetailModalView } from "src/modals/access-detail"
import { RowCellContainer } from "src/components/RowCellContainer"

const repository: Repository = "parakey"

const View = observer(() => {
    const store = useStore(AccessViewStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => await store.init(gstore.session.accessGroupId))()

        return () => store.dispose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, gstore.session.accessGroupId])

    const openDetailModalHandler = useCallback(
        (item) => {
            if (item != null) {
                gstore.modals.open(() => (
                    <AccessDetailModalView accessItem={item} />
                ))
            }
        },
        [gstore.modals],
    )
    const columns: IColumn<IAccessItem>[] = [
        {
            field: "name",
            headerName: t`access.forms-tab.title-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
        },
        {
            field: "type",
            headerName: t`access.forms-tab.type-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
        },
        {
            field: "parakey_resource_id",
            headerName: t`access.forms-tab.external-id-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
        },
    ]
    return (
        <ListPage
            header={{
                header: t`access-view.header`,
                breadcrumbs: [t`access-view.header`, t`access-view.breadcrumb`],
                createOptions: {
                    onClick() {
                        gstore.modals.open(() => <AccessDetailModalView />)
                    },
                    item: "",
                },
            }}
            loading={gstore.loading.is(AccessViewStore.LoadingKeys.init)}
        >
            <DataGridProTable
                paginator={store.accessList}
                data={store.accessList.items}
                advancedOperations={{
                    pagination: "server",
                }}
                columns={columns}
                onRowClickEvent={openDetailModalHandler}
                repository={repository}
                hideToolbar={true}
                loading={gstore.loading.is(AccessViewStore.LoadingKeys.loading)}
            />
        </ListPage>
    )
})

export const AccessView = observer(() => (
    <StoreProvider Store={AccessViewStore}>
        <View />
    </StoreProvider>
))
