import { t } from "@lingui/macro"
import { useCallback } from "react"
import { observer } from "mobx-react"
import { Box } from "@mui/material"

import { useStore } from "src/store/lib/useStore"
import { FormFields } from "src/modals/chatbot-configuration/components/formField"
import { AutoCompleteTextField } from "src/components/AutoCompleteTextField"
import { GlobalStore } from "src/store"
import { ChatbotConfigurationStore } from "src/modals/chatbot-configuration/store"
import { ConfigMode } from "src/types/dynamic-configurable-form-fields/config-mode"

export const MetaDataSection = observer(() => {
    const globalStore = useStore(GlobalStore)
    const store = useStore(ChatbotConfigurationStore)

    const handleSelectOnChange = useCallback(
        (newValue) => {
            store.form.set("config_type", newValue)
            const selectedConfigTypeConfigurationData =
                store.getSelectedConfigTypeConfigurationData()
            store.setPublishingLevel(
                selectedConfigTypeConfigurationData?.publishing_level,
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store.form],
    )

    return (
        <Box>
            <FormFields.ChatbotConfigurationTextInput
                variant="default"
                label={t`chatbot-configuration-detail.internal-name`}
                id="name"
                value={store.form.get("name")}
                rowLength={1}
                disabled={store.isReadOnly}
                maxLength={255}
                helperText={store.form.error("name") ?? undefined}
                error={Boolean(store.form.error("name"))}
                handleTextInputChange={(event) => {
                    store.form.set("name", event.target.value)
                }}
            />
            <AutoCompleteTextField
                disableCloseOnSelect
                multiSelect={store.configMode === ConfigMode.Multiple}
                options={store.configTypeOptions}
                defaultValues={store.form.get("config_type")}
                placeholder={t`chatbot-configuration-modal-location-filter-placeholder`}
                onChange={handleSelectOnChange}
                loading={globalStore.loading.is(
                    ChatbotConfigurationStore.LoadingKeys.init,
                )}
                sx={{
                    backgroundColor: "common.white",
                }}
                error={Boolean(store.form.error("config_type"))}
                helperText={store.form.error("config_type") ?? undefined}
            />
        </Box>
    )
})
