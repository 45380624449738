import { Typography, AccordionDetails } from "@mui/material"
import { useCallback } from "react"

import { DynamicConfigurableFormField } from "src/components/ConfigurableDynamicFormFields/formFields"

import { config_FieldType } from "src/api/models/config_FieldType"
import { IConfigurableSectionContentProps } from "src/components/ConfigurableDynamicFormFields/types/configurable-section"

export const ConfigurableSectionContent = ({
    sectionConfigurableFields,
    sectionName,
}: IConfigurableSectionContentProps) => {
    const getKey = (fieldName?: string) => `${sectionName}-${fieldName}`
    const showFieldNameAsHeading = useCallback(
        (type?: config_FieldType) =>
            type === "file" ||
            type === "icon" ||
            type === "image" ||
            type === "text_list",
        [],
    )

    return (
        <>
            {sectionConfigurableFields.map((field) => {
                return (
                    <AccordionDetails sx={{ mb: 3 }} key={getKey(field.name)}>
                        {showFieldNameAsHeading(field.type) && (
                            <Typography variant="subtitle2" gutterBottom>
                                {field.label}
                            </Typography>
                        )}
                        <Typography variant="body1" gutterBottom mb={2}>
                            {field.description}
                        </Typography>
                        <DynamicConfigurableFormField
                            sectionName={sectionName}
                            field={field}
                            key={getKey(field.name)}
                        />
                    </AccordionDetails>
                )
            })}
        </>
    )
}
