import { t } from "@lingui/macro"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"

import { FormPanel } from "src/components/FormPanel"
import { ModalTextField } from "src/components/ModalTextField"
import { SegmentTypeSelector } from "src/modals/segment-form/components/SegmentTypeSelector/index"
import { SegmentFormStore } from "src/modals/segment-form/store"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"

export const SegmentsSettingsSection = observer(() => {
    const store = useStore(SegmentFormStore)
    const globalStore = useStore(GlobalStore)

    const isSubmitting = globalStore.loading.is(
        SegmentFormStore.LoadingKeys.submit,
    )

    return (
        <FormPanel
            sections={[
                {
                    header: t`segment-form-modal.settings`,
                    content: (
                        <Stack spacing={2}>
                            <ModalTextField
                                variant="default"
                                label={t`segment-form-modal.segment-name`}
                                value={store.formFields.get("segment_name")}
                                onChange={store.formFields.setter(
                                    "segment_name",
                                )}
                                helperText={store.formFields.error(
                                    "segment_name",
                                )}
                                error={Boolean(
                                    store.formFields.error("segment_name"),
                                )}
                                disabled={isSubmitting}
                                rows={1}
                            />
                            <SegmentTypeSelector />
                        </Stack>
                    ),
                },
            ]}
        />
    )
})
