interface IUrlParamManager {
    setParam(key: string, value: string | string[]): void
    getParam(key: string): string | null
    getParamAsArray(key: string): string[]
    addToParam(key: string, value: string): void
    removeFromParam(key: string, value: string): void
    deleteParam(key: string): void
    clearParams(): void
    getAllParams(): Record<string, string | string[]>
}

type ParamValue = string[]
type ParamStore = Map<string, ParamValue>

export class UrlParamManager implements IUrlParamManager {
    private static instance: UrlParamManager
    private readonly params: ParamStore
    private readonly maxParamLength: number = 2048 // URL length safety limit
    private readonly paramChangeCallbacks: Set<() => void>

    private constructor() {
        this.params = new Map()
        this.paramChangeCallbacks = new Set()
        this.initializeFromUrl()
    }

    /**
     * Get singleton instance of UrlParamManager
     */
    public static getInstance(): UrlParamManager {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!UrlParamManager.instance) {
            UrlParamManager.instance = new UrlParamManager()
        }
        return UrlParamManager.instance
    }

    /**
     * Subscribe to parameter changes
     * @param callback Function to call when parameters change
     * @returns Unsubscribe function
     */
    public subscribe(callback: () => void): () => void {
        if (typeof callback !== "function") {
            throw new Error("Callback must be a function")
        }
        this.paramChangeCallbacks.add(callback)
        return () => this.paramChangeCallbacks.delete(callback)
    }

    private initializeFromUrl(): void {
        try {
            const searchParams = new URLSearchParams(window.location.search)
            searchParams.forEach((value, key) => {
                const decodedKey = decodeURIComponent(key)
                const decodedValues = value
                    .split(",")
                    .map((v) => decodeURIComponent(v))
                    .filter(Boolean)

                if (decodedValues.length > 0) {
                    this.params.set(decodedKey, decodedValues)
                }
            })
        } catch (error) {
            this.params.clear()
            throw new Error(`Error initializing URL parameters:${error}`)
        }
    }

    public setParam(key: string, value: string | string[]): void {
        this.validateKey(key)
        const values = Array.isArray(value) ? value : [value]
        const sanitizedValues = values
            .map((v) => this.sanitizeValue(v))
            .filter(Boolean)

        if (sanitizedValues.length > 0) {
            this.params.set(key, sanitizedValues)
        } else {
            this.params.delete(key)
        }

        this.updateUrl()
    }

    public getParam(key: string): string | null {
        const values = this.params.get(key)
        return values?.length != null ? values.join(",") : null
    }

    public getParamAsArray(key: string): string[] {
        return [...(this.params.get(key) ?? [])]
    }

    public addToParam(key: string, value: string): void {
        this.validateKey(key)
        const sanitizedValue = this.sanitizeValue(value)
        if (sanitizedValue.length === 0) return

        const currentValues = this.getParamAsArray(key)
        if (!currentValues.includes(sanitizedValue)) {
            this.setParam(key, [...currentValues, sanitizedValue])
        }
    }

    public removeFromParam(key: string, value: string): void {
        const currentValues = this.getParamAsArray(key)
        const sanitizedValue = this.sanitizeValue(value)
        const newValues = currentValues.filter((v) => v !== sanitizedValue)

        if (newValues.length > 0) {
            this.setParam(key, newValues)
        } else {
            this.deleteParam(key)
        }
    }

    public deleteParam(key: string): void {
        if (this.params.delete(key)) {
            this.updateUrl()
        }
    }

    public clearParams(): void {
        if (this.params.size > 0) {
            this.params.clear()
            this.updateUrl()
        }
    }

    public getAllParams(): Record<string, string | string[]> {
        const result: Record<string, string | string[]> = {}
        this.params.forEach((value, key) => {
            result[key] = value.length === 1 ? value[0] : [...value]
        })
        return Object.freeze(result) // Immutable return value
    }

    private validateKey(key: string): void {
        if (key.length === 0 || typeof key !== "string") {
            throw new Error("Invalid parameter key")
        }
        if (key.length > 100) {
            // Reasonable key length limit
            throw new Error("Parameter key too long")
        }
    }

    private sanitizeValue(value: string): string {
        return (
            value
                ?.trim()
                // eslint-disable-next-line no-control-regex
                .replace(/[\u0000-\u001F\u007F-\u009F]/g, "") // Remove control characters
                .substring(0, 1000)
        ) // Reasonable value length limit
    }

    private updateUrl(): void {
        try {
            const queryParts: string[] = []
            this.params.forEach((values, key) => {
                const encodedKey = encodeURIComponent(key)
                const encodedValues = values
                    .map((v) => encodeURIComponent(v))
                    .join(",")

                if (encodedValues.length > 0) {
                    queryParts.push(`${encodedKey}=${encodedValues}`)
                }
            })

            const queryString =
                queryParts.length > 0 ? `?${queryParts.join("&")}` : ""

            // Check URL length before updating
            const newUrl = `${window.location.pathname}${queryString}`
            if (newUrl.length > this.maxParamLength) {
                throw new Error("URL exceeds maximum length")
            }

            // Use replaceState instead of pushState to avoid breaking browser history
            window.history.replaceState(
                { urlParamManagerState: Object.fromEntries(this.params) },
                "",
                newUrl,
            )

            // Notify subscribers of parameter change
            this.paramChangeCallbacks.forEach((callback) => {
                try {
                    callback()
                } catch (error) {
                    throw new Error(
                        `Error in parameter change callback: ${error}`,
                    )
                }
            })
        } catch (error) {
            throw new Error(`Error updating URL: ${error}`)
        }
    }
}
