import {
    ISegmentType,
    ISegmentTypeGroupMap,
    SegmentTypeGroup,
} from "src/components/SegmentPicker/type"

export const SEGMENT_WHITELIST_TYPES: ISegmentForSearch["type"][] = [
    "propertyowner",
    "legalentity",
    "property",
    "building",
    "address",
    "propertyobject",
]

export const SEGMENT_TYPE_GROUP_STANDARD: ISegmentTypeGroupMap[SegmentTypeGroup.Standard] =
    [
        "propertyowner",
        "legalentity",
        "district",
        "area1",
        "area2",
        "area3",
        "property",
        "building",
        "address",
        "propertyobject",
    ]

export const ALLOWED_PREFIX_SEGMENT_TYPES: ISegmentType[] = [
    "district",
    "area1",
    "area2",
    "area3",
    "property",
    "propertyobject",
]
