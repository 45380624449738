import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { Box, Stack, useTheme } from "@mui/material"

import { useStore } from "src/store/lib/useStore"
import { AutoCompleteTextField } from "src/components/AutoCompleteTextField"
import { GlobalStore } from "src/store"
import { FeatureConfigBrandingStore } from "src/modals/feature-config-branding/store"
import { ConfigMode } from "src/types/dynamic-configurable-form-fields/config-mode"
import { AccessGroup } from "src/components/ModalAccessGroup/AccessGroup"
import { ModalTextField } from "src/components/ModalTextField"

export const MetaDataSection = observer(() => {
    const store = useStore(FeatureConfigBrandingStore)
    const globalStore = useStore(GlobalStore)
    const theme = useTheme()

    const isLoading = globalStore.loading.is(
        FeatureConfigBrandingStore.LoadingKeys.init,
    )

    const handleAccessGroupChange = (accessGroupId: number) => {
        store.setAccessGroupId(accessGroupId)
    }

    return (
        <Box>
            <Stack spacing={2}>
                <ModalTextField
                    variant="default"
                    label={t`global.internal-name`}
                    value={store.form.get("name")}
                    disabled={store.isReadOnly}
                    maxLength={255}
                    helperText={store.form.error("name") ?? undefined}
                    error={Boolean(store.form.error("name"))}
                    onChange={(event) => {
                        store.form.set("name", event.target.value)
                    }}
                />
                <AccessGroup
                    disabled={store.isReadOnly}
                    accessGroupId={store.form.get("access_group_id")}
                    errorMessage={
                        store.form.error("access_group_id") ?? undefined
                    }
                    onChange={handleAccessGroupChange}
                />
                <AutoCompleteTextField
                    disableCloseOnSelect
                    multiSelect={store.configMode === ConfigMode.Multiple}
                    options={store.configTypeOptions}
                    defaultValues={store.form.get("config_type")}
                    placeholder={t`feature-config-branding-modal-config-type-filter-placeholder`}
                    onChange={(newValue) => {
                        store.form.set("config_type", newValue)
                    }}
                    loading={isLoading}
                    sx={{
                        backgroundColor: theme.palette.common.white,
                    }}
                    error={Boolean(store.form.error("config_type"))}
                    helperText={store.form.error("config_type") ?? undefined}
                />
            </Stack>
        </Box>
    )
})
