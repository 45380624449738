import React from "react"

import { IChatbotConversationItem } from "./types/chatbot-conversation-item"

import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"

import { Channel } from "src/channel"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { TagsAdminService, tags_Tag } from "src/api"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { ChatbotAdminService } from "src/api/services/ChatbotAdminService"

export class ChatbotConversationStore implements IDisposable {
    static Context = React.createContext<ChatbotConversationStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading")
    private repositoryUpdatesListenerDisposer?: () => void

    private _segments: number[] = []
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private _allConversationIds: string[] = []
    _tags: tags_Tag[] | undefined

    get segments() {
        return this._segments
    }

    get tags() {
        return this._tags
    }

    get allConversationIds() {
        return this._allConversationIds
    }

    chatbotList = new Pagination(
        async (params) => {
            const response =
                await ChatbotAdminService.postV1AdminChatbotConversations({
                    request: {
                        segment_ids: this._segments,
                        filter: params.advanceQuery,
                    },
                    pageNumber: params.page,
                    pageSize: params.pageSize,
                    accessGroupId: this.getAccessGroupId(),
                })

            const items: IChatbotConversationItem[] = (
                response.conversations ?? []
            ).map((item) => ({
                id: item.conversation_id ?? "",
                conversation_id: item.conversation_id ?? "",
                tenant_id: item.tenant_id ?? 0,
                resolution: item.resolution ?? "",
                tenant_feedback_reason: item.tenant_feedback?.reason ?? "",
                tenant_feedback_thumb_status:
                    item.tenant_feedback?.thumb_status ?? -1,
                admin_feedback_reason: item.admin_feedback?.reason ?? "",
                admin_feedback_thumb_status:
                    item.admin_feedback?.thumb_status ?? -1,
                impersonated_by: item.impersonated_by,
                tags: item.tags,
                created_at:
                    item.created_at !== undefined
                        ? new Date(item.created_at)
                        : undefined,
                ended_at: item.ended_at ?? "",
                updated_at: item.updated_at ?? "",
                language: item.language ?? "",
                property_object_segment_id:
                    item.property_object_segment_id ?? -1,
                property_owner_name: item.property_owner_name ?? "",
                entities: item.entities ?? [],
                no_response_count: item.no_response_count ?? 0,
                is_self_service: item.is_self_service ?? false,
                dispatch_count: item.dispatch_count ?? 0,
                clarification_count: item.clarification_count ?? 0,
            }))

            this.setAllConversationIds(
                items.map((item) => item.conversation_id),
            )

            return { items, count: response.total_count ?? 0 }
        },
        {
            loadingKey: ChatbotConversationStore.LoadingKeys.loading,
        },
    )

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }
    private listenToChatbotConversationRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "chatbotConversation"
                ) {
                    await this.chatbotList.reload()
                }
            },
        )
    }
    @loads(() => ChatbotConversationStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery: IAdvanceQueryModel) {
        this.listenToChatbotConversationRepositoryUpdated()
        this.setAccessGroupId(accessGroupId)
        await this.chatbotList.loadInitialPage(advanceQuery)
        this._tags = await this.getTags()
    }

    async getTags() {
        const response = await TagsAdminService.postV1AdminTagList({
            request: { page_size: 10000 },
        })

        return response.tags
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.chatbotList.loadAdvanceQuery(advanceQuery)
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }
    private setAccessGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private getAccessGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }

    private setAllConversationIds(allConversationIds: string[]) {
        this._allConversationIds = allConversationIds
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.chatbotList.loadInitialPage()
    }
}
