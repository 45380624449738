import { observer } from "mobx-react"

import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { useStore } from "src/store/lib/useStore"
import { ISegmentItemListPrefixProps } from "src/components/SegmentPicker/components/SegmentItemListPrefix/types"
import { SegmentEditPrefix } from "src/components/SegmentPicker/components/SegmentItemListPrefix/SegmentEditPrefix"
import { SegmentRadioPrefix } from "src/components/SegmentPicker/components/SegmentItemListPrefix/SegmentRadioPrefix"
import { SegmentCheckboxPrefix } from "src/components/SegmentPicker/components/SegmentItemListPrefix/SegmentCheckboxPrefix"

export const SegmentItemListPrefix = observer(
    (props: ISegmentItemListPrefixProps) => {
        const store = useStore(SegmentPickerStore)

        switch (store.segmentListItemPrefixTypeValue) {
            case "edit":
                return <SegmentEditPrefix segment={props.segment} />
            case "radio":
                return <SegmentRadioPrefix segment={props.segment} />
            case "checkbox":
            default:
                return <SegmentCheckboxPrefix segment={props.segment} />
        }
    },
)
