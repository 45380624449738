import { Chip, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useMemo } from "react"

import { Status } from "src/types/status"
import { StatusCasesV2 } from "src/types/status-casesV2"

interface IProps {
    label: string
    state: "positive" | "neutral" | "negative" | "info" | "other" | "dark"
    icon?: React.ReactElement
    variant?: "outlined" | "filled"
}

export type IStatusChipProps = IProps

export interface IStatusValueOptions {
    value: Status | StatusCasesV2
    label: string
}

export const StatusChip = observer(
    ({ state, variant = "filled", label, icon }: IProps) => {
        const theme = useTheme()

        const grey900 = theme.palette.grey[900]
        const stateColors: Record<
            string,
            { bgColor: string; textColor: string }
        > = useMemo(
            () => ({
                negative: {
                    bgColor: theme.palette.utility.rustRedFaded.main,
                    textColor: grey900,
                },
                positive: {
                    bgColor: theme.palette.utility.appleFaded.main,
                    textColor: grey900,
                },
                neutral: {
                    bgColor: theme.palette.utility.orangeFaded.main,
                    textColor: grey900,
                },
                info: {
                    bgColor: theme.palette.utility.blueFaded.main,
                    textColor: grey900,
                },
                other: {
                    bgColor: theme.palette.grey[300],
                    textColor: grey900,
                },
                dark: {
                    bgColor: theme.palette.grey[700],
                    textColor: theme.palette.common.white,
                },
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [],
        )
        const colors = useMemo(
            () =>
                stateColors[state] ?? {
                    bgColor: theme.palette.grey[100],
                    textColor: grey900,
                },
            [grey900, state, stateColors, theme.palette.grey],
        )

        // Extracted styles
        const backgroundColor =
            variant === "filled" ? colors.bgColor : "transparent"
        const textColor = variant === "filled" ? colors.textColor : "inherit"
        const border =
            variant === "outlined" ? `1.5px solid ${colors.bgColor}` : "none"
        const iconStyles = useMemo(
            () =>
                icon !== undefined
                    ? {
                          fontSize: "15px",
                          color:
                              label === "Negative"
                                  ? theme.palette.generalColors.red
                                  : theme.palette.generalColors.green,
                      }
                    : undefined,
            [icon, label, theme.palette.generalColors],
        )

        return (
            <Chip
                label={label}
                variant={variant}
                icon={icon}
                sx={{
                    backgroundColor,
                    color: textColor,
                    border,
                    "& .MuiChip-icon": iconStyles,
                }}
            />
        )
    },
)
