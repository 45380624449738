import { MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"

import { Flag, ItemContainer } from "src/layouts/DefaultLayout/styled"

import { useUniqueId } from "src/lib/unique-id"
import { i18n } from "src/locales"
import { Locale, Locales } from "src/locales/locale"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

interface IProps {
    fullWidth?: boolean
}

export const I18nSelect = observer((props: IProps) => {
    const labelId = useUniqueId()
    const inputId = useUniqueId()

    const { session } = useStore(GlobalStore)

    const handleLanguageChange = useCallback(
        (event: SelectChangeEvent<Locale>) => {
            i18n.activate(event.target.value)
        },
        [],
    )

    const renderValue = useCallback(
        (value: Locale) => (
            <ItemContainer>
                <Flag src={Locales[value].icon} />
                {Locales[value].name}
            </ItemContainer>
        ),
        [],
    )

    const renderMenuItems = useMemo(
        () =>
            Object.values(Locales).map(({ key }) => (
                <MenuItem value={key} key={key}>
                    {renderValue(key)}
                </MenuItem>
            )),
        [renderValue],
    )

    return (
        <Select
            labelId={labelId}
            id={inputId}
            value={session.language}
            onChange={handleLanguageChange}
            size="small"
            variant="filled"
            renderValue={renderValue}
            fullWidth={props.fullWidth}
        >
            {renderMenuItems}
        </Select>
    )
})
