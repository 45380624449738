import { t } from "@lingui/macro"
import { useMemo } from "react"

import { ISegmentType } from "src/components/SegmentPicker/type"

export const useSegmentTypeTranslations = () => {
    return useMemo(
        (): { [key in ISegmentType]: string } => ({
            legalentity: t`segment-picker.legalentity-segment-type`,
            property: t`segment-picker.property-segment-type`,
            propertyowner: t`segment-picker.property-owner-segment-type`,
            building: t`segment-picker.building-segment-type`,
            address: t`segment-picker.address-segment-type`,
            propertyobject: t`segment-picker.object-segment-type`,
            district: t`segment-picker.district-segment-type`,
            area1: t`segment-picker.area1-segment-type`,
            area2: t`segment-picker.area2-segment-type`,
            area3: t`segment-picker.area3-segment-type`,
            other: t`segment-picker.other-segment-type`,
        }),
        [],
    )
}
