import { Chip, Tooltip, Typography } from "@mui/material"

import { ITableChipCellProps } from "src/types/table-chip-cell"

export const TableChipCell = ({
    value,
    label,
    sxProps,
}: ITableChipCellProps) => {
    const values = Object.values(value ?? {})
    const isSingleValue =
        values != null && values?.length > 0 && values?.length === 1

    return isSingleValue ? (
        <Chip label={values[0]} sx={sxProps} />
    ) : (
        <Tooltip title={values.join(", ")} arrow>
            <Typography variant="body2">
                {`(${values?.length}) ${label}`}
            </Typography>
        </Tooltip>
    )
}
