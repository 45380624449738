import { t } from "@lingui/macro"
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useUniqueId } from "src/lib/unique-id"
import { CONFIGURABLE_LANGUAGE_VALUES } from "src/modals/feature-configuration-detail/constants/configurableLanguage"
import { currencyValues } from "src/modals/feature-configuration-detail/constants/marketValues"
import { FeatureConfigurationDetailStore } from "src/modals/feature-configuration-detail/store"
import { IConfigurableLanguage } from "src/modals/feature-configuration-detail/types/configurable-language"
import { useStore } from "src/store/lib/useStore"

export const MarketSection = observer(() => {
    const store = useStore(FeatureConfigurationDetailStore)
    const selectCurrencyId = useUniqueId()
    const selectActiveLanguagesId = useUniqueId()

    const handleSelectChange = useCallback(
        (
            event: SelectChangeEvent<string | IConfigurableLanguage>,
            fieldName: "currency" | "activeLanguages",
        ) => {
            store.form.set(fieldName, event?.target.value as string)
        },
        [store.form],
    )

    const renderSelectField = (
        fieldName: "currency" | "activeLanguages",
        labelId: string,
        values: typeof currencyValues | typeof CONFIGURABLE_LANGUAGE_VALUES,
        headerKey: string,
        labelKey: string,
    ) => (
        <FormFieldSet header={headerKey}>
            <FormControl fullWidth>
                <InputLabel id={labelId}>{labelKey}</InputLabel>
                <Select
                    labelId={labelId}
                    value={store.form.get(fieldName)}
                    onChange={(event) => handleSelectChange(event, fieldName)}
                    label={labelKey}
                >
                    {Object.entries(values).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                            {key}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </FormFieldSet>
    )

    return (
        <>
            {renderSelectField(
                "currency",
                selectCurrencyId,
                currencyValues,
                t`feature-configuration-detail-modal.market-section.currency-header`,
                t`feature-configuration-detail-modal.market-section.currency-label`,
            )}
            {renderSelectField(
                "activeLanguages",
                selectActiveLanguagesId,
                CONFIGURABLE_LANGUAGE_VALUES,
                t`feature-configuration-detail-modal.market-section.language-header`,
                t`feature-configuration-detail-modal.market-section.language-label`,
            )}
        </>
    )
})
