import { t } from "@lingui/macro"
import { useMemo } from "react"

import { NoWrap } from "src/components/NoWrap"
import { RowCellContainer } from "src/components/RowCellContainer"
import { TableChipCell } from "src/components/TableChipCell"
import { Time } from "src/components/Time"
import { IColumn } from "src/types/data-grid-pro"
import { parseStatus } from "src/types/chatbot"
import { ChatbotStatusChip } from "src/views/chatbot/components/ChatbotChip"
import { FadedRowCellContainer } from "src/views/chatbot/styles"
import { IChatbotConversationItem } from "src/views/chatbot/types/chatbot-conversation-item"
import { chatbotConversationsConstants } from "src/views/chatbot/constants"
import { SimulatedStatusChip } from "src/views/chatbot/components/SimulatedStatusChip"

export const useGetChatbotColumns = (
    tagsValueOptions: { label: string; value: number | undefined }[],
): IColumn<IChatbotConversationItem>[] => {
    return useMemo(
        () => [
            {
                field: "conversation_id",
                headerName: t`chatbot-view.list.conversation-id`,
                description: t`chatbot-view.list.conversation-id-description`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                type: "string",
                sortable: false,
                minWidth: 250,
            },
            {
                field: "created_at",
                headerName: t`chatbot-view.list.start-date`,
                description: t`chatbot-view.list.start-date-description`,
                minWidth: 110,
                renderCell: (params) => <Time date={params.value} />,
                type: "dateTime",
            },
            {
                field: "tenant_id",
                headerName: t`chatbot-view.list.tenant-id`,
                description: t`chatbot-view.list.tenant-id-description`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                type: "number",
                sortable: false,
            },
            {
                field: "tags",
                headerName: t`chatbot-view.list.tags`,
                description: t`chatbot-view.list.tags-description`,
                renderCell: (params) => (
                    <TableChipCell
                        value={params.value}
                        label={t`tags-cell-component.tags`.toLowerCase()}
                    />
                ),
                type: "singleSelect",
                // @ts-ignore
                // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
                valueOptions: tagsValueOptions,
                sortable: false,
            },
            {
                field: "tenant_feedback_thumb_status",
                headerName: t`chatbot-view.list.end-user-feedback`,
                description: t`chatbot-view.list.end-user-feedback-description`,
                renderCell: (params) =>
                    params.value !== -1 ? (
                        <NoWrap>
                            <ChatbotStatusChip
                                status={
                                    params.value === 1
                                        ? parseStatus("positive")
                                        : parseStatus("negative")
                                }
                            />
                        </NoWrap>
                    ) : (
                        <FadedRowCellContainer>
                            {parseStatus("unknown")}
                        </FadedRowCellContainer>
                    ),
                type: "singleSelect",
                // @ts-ignore
                // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
                valueOptions:
                    chatbotConversationsConstants.feedbackThumbStatusOptions,
                sortable: false,
            },
            {
                field: "tenant_feedback_reason",
                headerName: t`chatbot-view.list.tenant-feedback-reason`,
                description: t`chatbot-view.list.tenant-feedback-reason-description`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                sortable: false,
                filterable: false,
                type: "singleSelect",
                // @ts-ignore
                // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
                valueOptions:
                    chatbotConversationsConstants.tenantFeedbackReasons,
            },
            {
                field: "admin_feedback_thumb_status",
                headerName: t`chatbot-view.list.admin-feedback`,
                description: t`chatbot-view.list.admin-feedback-description`,
                renderCell: (params) =>
                    params.value !== -1 ? (
                        <NoWrap>
                            <ChatbotStatusChip
                                status={
                                    params.value === 1
                                        ? parseStatus("positive")
                                        : parseStatus("negative")
                                }
                            />
                        </NoWrap>
                    ) : (
                        <FadedRowCellContainer>
                            {parseStatus("unknown")}
                        </FadedRowCellContainer>
                    ),
                type: "singleSelect",
                // @ts-ignore
                // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
                valueOptions:
                    chatbotConversationsConstants.feedbackThumbStatusOptions,
                sortable: false,
            },
            {
                field: "impersonated_by",
                headerName: t`chatbot-view.list.is-simulated`,
                description: t`chatbot-view.list.is-simulated-description`,
                renderCell: (params) => (
                    <SimulatedStatusChip
                        labelSimulated={t`chatbot-view.list.user-simulated`}
                        labelNotSimulated={t`chatbot-view.list.user-not-simulated`}
                        value={params.value}
                    />
                ),
                sortable: false,
                type: "boolean",
            },
            {
                field: "no_response_count",
                headerName: t`chatbot-view.list.no-response-count`,
                description: t`chatbot-view.list.no-response-count-description`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                sortable: false,
                type: "number",
            },
            {
                field: "is_self_service",
                headerName: t`chatbot-view.list.is-self-service`,
                description: t`chatbot-view.list.is-self-service-description`,
                renderCell: (params) => (
                    <RowCellContainer>
                        {Boolean(params.value) ? t`global.yes` : t`global.no`}
                    </RowCellContainer>
                ),
                sortable: false,
                type: "boolean",
            },
            {
                field: "dispatch_count",
                headerName: t`chatbot-view.list.dispatch-count`,
                description: t`chatbot-view.list.dispatch-count-description`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                sortable: false,
                type: "number",
            },
            {
                field: "clarification_count",
                headerName: t`chatbot-view.list.clarification-count`,
                description: t`chatbot-view.list.clarification-count-description`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                sortable: false,
                type: "number",
            },
        ],
        [tagsValueOptions],
    )
}
