import { observer } from "mobx-react"
import { Box, TextField } from "@mui/material"
import { useState, useEffect, useCallback, useMemo } from "react"
import { t } from "@lingui/macro"

import { useStore } from "src/store/lib/useStore"
import { ConfigurableDynamicFormFieldsStore } from "src/components/ConfigurableDynamicFormFields/store"
import { getSectionFieldName } from "src/components/ConfigurableDynamicFormFields/helpers/fieldNameHelpers"
import { IConfigurableDynamicFormFieldsProps } from "src/components/ConfigurableDynamicFormFields/types/form-field"
import { ModalTextField } from "src/components/ModalTextField"
import { DEFAULT_COLOR } from "src/components/ConfigurableDynamicFormFields/constants"
import { isValidHexColor } from "src/components/ConfigurableDynamicFormFields/helpers/isValidHexColor"

export const ConfigurableDynamicFormFieldsColorPicker = observer(
    ({ sectionName, field }: IConfigurableDynamicFormFieldsProps) => {
        const store = useStore(ConfigurableDynamicFormFieldsStore)
        const formFieldKey = getSectionFieldName(sectionName, field.name)
        const storedValue = store.form.get(formFieldKey) as string
        const colorValue = storedValue ?? DEFAULT_COLOR

        const [hexInput, setHexInput] = useState(colorValue)
        const [hexError, setHexError] = useState<string>("")

        const errorMessage = useMemo(
            () => hexError ?? store.form.error(formFieldKey) ?? "",
            [hexError, store.form, formFieldKey],
        )
        const hasError = useMemo(
            () => Boolean(hexError ?? store.form.error(formFieldKey)),
            [hexError, store.form, formFieldKey],
        )

        useEffect(() => {
            setHexInput(colorValue)
        }, [colorValue])

        const handleColorPickerChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                store.form.set(formFieldKey, e.target.value.toUpperCase())
            },
            [store.form, formFieldKey],
        )

        const handleHexChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value.toUpperCase()
                setHexInput(value)
                if (isValidHexColor(value)) {
                    store.form.set(formFieldKey, value)
                    setHexError("")
                } else {
                    setHexError(
                        value.length > 0 ? t`errors.invalid-hex-color` : "",
                    )
                    if (value.length === 7) {
                        // If we have a complete hex code but it's invalid, revert to the last valid value
                        setHexInput(colorValue)
                    }
                }
            },
            [colorValue, formFieldKey, store.form],
        )

        return (
            <Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <TextField
                        type="color"
                        value={colorValue}
                        onChange={handleColorPickerChange}
                        error={hasError}
                        disabled={store.isReadOnly}
                        sx={{
                            width: "120px",
                            "& input[type=color]": {
                                width: "100%",
                                height: "40px",
                                padding: "2px",
                                cursor: "pointer",
                            },
                        }}
                        inputProps={{
                            "aria-label": field.label,
                        }}
                    />
                    <ModalTextField
                        variant="withToolTip"
                        tooltipText={field.tooltip_key ?? ""}
                        label={field.label}
                        value={hexInput}
                        onChange={handleHexChange}
                        error={hasError}
                        helperText={errorMessage}
                        disabled={store.isReadOnly}
                        size="small"
                    />
                </Box>
            </Box>
        )
    },
)
