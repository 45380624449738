import { t } from "@lingui/macro"
import {
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { NoSegmentsFound } from "src/components/SegmentPicker/components/NoSegmentsFound"
import { SegmentParent } from "src/components/SegmentPicker/components/SegmentParent"
import { SearchField } from "src/components/SegmentPicker/components/SearchField"
import { SegmentCountFound } from "src/components/SegmentPicker/components/SegmentCountFound"
import { SegmentsList } from "src/components/SegmentPicker/components/SegmentsList"
import { SegmentType } from "src/components/SegmentPicker/components/SegmentType"
import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { useStore } from "src/store/lib/useStore"
import { ISegmentPickerSearchProps } from "src/components/SegmentPicker/SegmentPickerSearch/types"
import { SegmentTypeGroup } from "src/components/SegmentPicker/type"

export const SegmentPickerSearch = observer(
    (props: ISegmentPickerSearchProps) => {
        const theme = useTheme()
        const smallScreen = useMediaQuery(theme.breakpoints.down("md"))
        const store = useStore(SegmentPickerStore)

        const handleSegmentTypeGroupChange = useCallback(
            async (_, value: SegmentTypeGroup) => {
                store.setSegmentTypeGroup(value)
                await store.availableSegments.loadInitialPage()
            },
            [store],
        )

        return (
            <Stack
                sx={{ height: "100%" }}
                data-testid="SegmentPickerModal/Search"
            >
                <Stack spacing={2}>
                    {!smallScreen && (
                        <Typography
                            component="h2"
                            fontSize="1.25rem"
                            lineHeight={2}
                            fontWeight={500}
                        >{t`segment-picker.available.search-header`}</Typography>
                    )}
                    {store.hasSegmentsInGroup(SegmentTypeGroup.Custom) &&
                        store.hasSegmentTypesInAllowedSegments(
                            SegmentTypeGroup.Custom,
                        ) && (
                            <Tabs
                                value={store.segmentTypeGroup}
                                onChange={handleSegmentTypeGroupChange}
                                variant="fullWidth"
                            >
                                <Tab
                                    label={t`segment-picker.available.standard-segment-group-tab`}
                                    value={SegmentTypeGroup.Standard}
                                />
                                <Tab
                                    label={t`segment-picker.available.custom-segment-group-tab`}
                                    value={SegmentTypeGroup.Custom}
                                />
                            </Tabs>
                        )}
                    {store.parent != null ? <SegmentParent /> : <SearchField />}
                    <SegmentType />
                    <SegmentCountFound />
                </Stack>
                <NoSegmentsFound />
                <SegmentsList
                    hasSelectAllCheckbox={props.hasSelectAllCheckbox}
                />
            </Stack>
        )
    },
)
