//#region Imports
import { makeAutoObservable } from "mobx"
import React from "react"

import { t } from "@lingui/macro"

import { createLoadingKeys } from "src/lib/loading"
import { FormFields } from "src/lib/form-fields"
import {
    IParentSegment,
    ISegmentFormFields,
} from "src/modals/segment-form/type"
import { loads } from "src/channel/utils"
import { SegmentsAdminService } from "src/api"
import { ISegmentType } from "src/components/SegmentPicker/type"
import { Channel } from "src/channel"
import { ALLOWED_PREFIX_SEGMENT_TYPES } from "src/components/SegmentPicker/constants"
//#endregion

//#region Class Definition
export class SegmentFormStore {
    static Context = React.createContext<SegmentFormStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")
    //#endregion

    //#region Observable Properties
    formFields = new FormFields<ISegmentFormFields>({})
    isLoading = false
    parentSegment: IParentSegment | null = null
    allowedSegmentTypes: ISegmentType[] | null = null
    //#endregion

    //#region Constructor
    constructor() {
        makeAutoObservable(this)
    }
    //#endregion

    //#region Initialization Methods
    @loads(() => SegmentFormStore.LoadingKeys.init)
    async init(segmentId: number, allowedSegmentTypes?: ISegmentType[]) {
        this.allowedSegmentTypes =
            allowedSegmentTypes ?? ALLOWED_PREFIX_SEGMENT_TYPES

        if (segmentId !== undefined) {
            await this.getSegmentDetails(segmentId)
        } else {
            this.formFields.init({})
        }
    }

    async getSegmentDetails(segmentId: number) {
        const segmentDetails = await SegmentsAdminService.getV1AdminSegment1({
            segmentId,
        })

        // Initialize form fields with original values to properly track changes
        this.formFields.init({
            segment_id: segmentDetails.id ?? -1,
            segment_name: segmentDetails.name ?? "",
            segment_type: segmentDetails.type as ISegmentType,
            parent_segment_id:
                segmentDetails.parent_id != null
                    ? [segmentDetails.parent_id]
                    : [],
        })

        // If there's a parent_id, update the parent segment details
        if (segmentDetails.parent_id != null) {
            await this.updateParentSegment()
        }

        return segmentDetails
    }
    //#endregion

    //#region Parent Segment Methods
    async updateParentSegment() {
        const parentId = this.formFields.get("parent_segment_id")?.[0]
        if (parentId != null) {
            const details = await SegmentsAdminService.getV1AdminSegment1({
                segmentId: parentId,
            })
            if (details != null) {
                this.parentSegment = {
                    id: details.id ?? -1,
                    name: details.name ?? "",
                    type: details.type as ISegmentType,
                    tenantCount: details.tenants?.length ?? 0,
                    path: details.path ?? "",
                }
            }
        } else {
            this.parentSegment = null
        }
    }

    async updateParentSegmentAndFormFields(segmentIds: number[]) {
        this.formFields.set("parent_segment_id", segmentIds)
        await this.updateParentSegment()
    }
    //#endregion

    //#region Save and Validation Methods
    @loads(() => SegmentFormStore.LoadingKeys.submit)
    async save(onSuccess?: () => void) {
        const segmentId = this.formFields.get("segment_id")
        const segmentName = this.formFields.get("segment_name") ?? ""
        const segmentData = {
            name: segmentName,
            parent_id: this.formFields.get("parent_segment_id")?.[0],
        }

        try {
            this.validateFields()
            if (this.formFields.hasErrors()) return false

            if (segmentId != null) {
                // Update existing segment
                await SegmentsAdminService.patchV1AdminSegment({
                    segmentId,
                    request: segmentData,
                })
                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "segments",
                        action: "update",
                        item: {
                            id: segmentId,
                            name: segmentName,
                        },
                    },
                })
            } else {
                // Create new segment
                const result = await SegmentsAdminService.postV1AdminSegment({
                    request: segmentData,
                })
                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "segments",
                        action: "create",
                        item: {
                            id: result.id ?? -1,
                            name: segmentName,
                        },
                    },
                })
            }
            onSuccess?.()
            return true
        } catch (error) {
            this.formFields.setError("generic", t`errors.required`)
            return false
        }
    }

    private validateFields(): void {
        this.formFields.validateRequiredFields([
            { field: "segment_name" },
            {
                field: "segment_type",
            },
            {
                field: "parent_segment_id",
                validate: (value: string | number | number[] | undefined) =>
                    Array.isArray(value) && value.length > 0,
            },
        ])
    }
    //#endregion
}
