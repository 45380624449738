import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { useUniqueId } from "src/lib/unique-id"
import { SegmentFormStore } from "src/modals/segment-form/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { SegmentTypeIcon } from "src/components/SegmentPicker/components/SegmentTypeIcon"
import { ISegmentType } from "src/components/SegmentPicker/type"
import { useSegmentTypeTranslations } from "src/components/SegmentPicker/hooks/useSegmentTypeTranslations"

export const SegmentTypeSelector = observer(() => {
    const store = useStore(SegmentFormStore)
    const globalStore = useStore(GlobalStore)
    const segmentTypeUniqueId = useUniqueId()
    const translations = useSegmentTypeTranslations()

    const [segmentType, setSegmentType] = useState("")
    const isSubmitting = globalStore.loading.is(
        SegmentFormStore.LoadingKeys.submit,
    )

    const formFieldsSegmentType = store.formFields.get("segment_type")

    useEffect(() => {
        if (formFieldsSegmentType !== undefined) {
            setSegmentType(formFieldsSegmentType as string)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFieldsSegmentType])

    const handleSegmentTypeChange = useCallback(
        (value: string) => {
            setSegmentType(value as string)
            store.formFields.set("segment_type", value as string)
        },
        [store.formFields],
    )

    const filteredSegmentTypes = useMemo(() => {
        const types =
            globalStore.session.selectedParakeyType === "Private"
                ? (Object.keys(translations).filter(
                      (type) => type === "propertyobject",
                  ) as ISegmentType[])
                : (Object.keys(translations) as ISegmentType[])

        return store.allowedSegmentTypes != null
            ? types.filter((type) => store.allowedSegmentTypes?.includes(type))
            : types
    }, [
        globalStore.session.selectedParakeyType,
        store.allowedSegmentTypes,
        translations,
    ])

    const renderSelectMenuItems = useMemo(
        () =>
            filteredSegmentTypes.map((type) => (
                <MenuItem
                    key={type}
                    value={type}
                    sx={{ paddingTop: 2, paddingBottom: 2 }}
                >
                    <Stack direction="row" alignItems="center">
                        <SegmentTypeIcon type={type} size={20} />
                        <Stack width={10} />
                        {translations[type]}
                    </Stack>
                </MenuItem>
            )),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filteredSegmentTypes],
    )

    return (
        <Box sx={{ minWidth: 120, "& ul": { paddingTop: 0 } }}>
            <FormControl
                fullWidth
                error={Boolean(store.formFields.error("segment_type"))}
            >
                <InputLabel
                    id={segmentTypeUniqueId}
                >{t`segment-form-modal.segment-type`}</InputLabel>
                <Select
                    labelId={segmentTypeUniqueId}
                    id="segment-type-select"
                    value={segmentType}
                    label={t`segment-form-modal.segment-type`}
                    onChange={(event) =>
                        handleSegmentTypeChange(event.target.value)
                    }
                    disabled={isSubmitting}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                "& .MuiMenu-list": {
                                    padding: 0,
                                },
                            },
                        },
                    }}
                >
                    {renderSelectMenuItems}
                </Select>
                {Boolean(store.formFields.error("segment_type")) && (
                    <FormHelperText>
                        {store.formFields.error("segment_type")}
                    </FormHelperText>
                )}
            </FormControl>
        </Box>
    )
})
