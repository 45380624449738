import { t } from "@lingui/macro"
import { useMemo } from "react"
import { Tooltip, Typography } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"

import { IColumn } from "src/types/data-grid-pro"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { IFeatureConfigBrandingItem } from "src/views/feature-config-branding/types"
import { RowCellContainer } from "src/components/RowCellContainer"

export const useFeatureConfigBrandingColumns = () => {
    const columns = useMemo<IColumn<IFeatureConfigBrandingItem>[]>(
        () => [
            {
                field: "name",
                headerName: t`global.column.internal-name`,
                renderCell: (
                    params: GridRenderCellParams<IFeatureConfigBrandingItem>,
                ) => <RowCellContainer>{params.value}</RowCellContainer>,
                type: "string",
                minWidth: 120,
            },
            {
                field: "segment_names",
                headerName: t`global.column.visible-for`,
                renderCell: (
                    params: GridRenderCellParams<IFeatureConfigBrandingItem>,
                ) => (
                    <div style={{ cursor: "pointer" }}>
                        <Tooltip
                            arrow
                            title={params?.row?.segment_names?.join(", ") ?? ""}
                        >
                            <Typography>
                                {`${
                                    params.value?.length
                                } ${t`global.segments`.toLowerCase()}`}
                            </Typography>
                        </Tooltip>
                    </div>
                ),
                type: "string",
                minWidth: 178,
            },
            {
                field: "access_type",
                headerName: t`global.column.access-type`,
                renderCell: (
                    params: GridRenderCellParams<IFeatureConfigBrandingItem>,
                ) => <AccessTypeHeader accessType={params.value} />,
                type: "string",
                filterable: false,
                sortable: false,
                flex: 1,
            },
        ],
        [],
    )

    return columns
}
