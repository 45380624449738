import { content_ContentItemConfigType } from "src/api"

export const configTypeSwitch = (configType: content_ContentItemConfigType) => {
    const configTypePriorityMap: Record<content_ContentItemConfigType, string> =
        {
            navigation: "priority_main_navigation",
            move_in_guide: "priority_move_in_guide",
            overview: "priority_overview",
            spotlight: "priority_spotlight",
            move_in_wizard: "priority_move_in_wizard",
            my_subscriptions: "priority_my_subscriptions",
        }
    return configTypePriorityMap[configType]
}
