import { StatusChip } from "src/components/StatusChip"
import { ISimulatedStatusChipProps } from "src/views/chatbot/types/simulated-status-chip"

export const SimulatedStatusChip = ({
    value,
    labelSimulated,
    labelNotSimulated,
}: ISimulatedStatusChipProps) => {
    if (value === null || value === undefined) return <></>

    const _value = Boolean(value)
    return (
        <StatusChip
            label={_value === true ? labelSimulated : labelNotSimulated}
            state="dark"
            variant={_value === true ? "filled" : "outlined"}
        />
    )
}
