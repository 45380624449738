import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { FormsViewStore } from "./store"
import { IFormItem } from "./type"

import { ListPage } from "src/components/ListPage"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { IColumn } from "src/types/data-grid-pro"
import { Repository } from "src/types/channel"
import { FormDetailModalView } from "src/modals/contact-forms-form-builder"
import { RowCellContainer } from "src/components/RowCellContainer"

const repository: Repository = "contact-forms"

const View = observer(() => {
    const store = useStore(FormsViewStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => await store.init(gstore.session.accessGroupId))()

        return () => store.dispose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, gstore.session.accessGroupId])

    const openDetailModalHandler = useCallback(
        (item) => {
            if (item != null) {
                gstore.modals.open(() => <FormDetailModalView id={item.id} />)
            }
        },
        [gstore.modals],
    )
    const columns: IColumn<IFormItem>[] = [
        {
            field: "form_id",
            headerName: t`contact-form-forms-view.formId.title-header`,
            width: 200,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
        },
        {
            field: "name",
            headerName: t`contact-form-forms-view.name.title-header`,
            width: 200,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
        },
        {
            field: "type",
            headerName: t`contact-form-forms-view.type.title-header`,
            width: 200,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
        },
        {
            field: "published_to",
            headerName: t`contact-form-forms-view.published.title-header`,
            width: 150,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
        },
    ]
    return (
        <ListPage
            header={{
                header: t`contact-forms-forms-view.header`,
                breadcrumbs: [
                    t`contact-forms-view.header`,
                    t`contact-forms-forms-view.header`,
                ],
                createOptions: {
                    onClick() {
                        gstore.modals.open(() => <FormDetailModalView />)
                    },
                    item: "",
                },
            }}
            loading={gstore.loading.is(FormsViewStore.LoadingKeys.init)}
        >
            <DataGridProTable
                paginator={store.formList}
                data={store.formList.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                }}
                onRowClickEvent={openDetailModalHandler}
                repository={repository}
                hideToolbar={true}
                loading={gstore.loading.is(FormsViewStore.LoadingKeys.loading)}
            />
        </ListPage>
    )
})

export const FormsView = observer(() => (
    <StoreProvider Store={FormsViewStore}>
        <View />
    </StoreProvider>
))
