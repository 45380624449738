import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router"

import { Stack, SxProps, useTheme } from "@mui/material"

import { ListPage } from "src/components/ListPage"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { SegmentPickerSearch } from "src/components/SegmentPicker/SegmentPickerSearch/index"
import { SegmentFormModal } from "src/modals/segment-form"
import { ALLOWED_PREFIX_SEGMENT_TYPES } from "src/components/SegmentPicker/constants"

const View = observer(() => {
    const store = useStore(SegmentPickerStore)
    const globalStore = useStore(GlobalStore)
    const { palette } = useTheme()
    const { segmentId } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        ;(async () => {
            await store.init({
                accessGroupId: globalStore.session.accessGroupId,
                initialSegmentIds: [],
                allowedPrefixSegmentTypes: ALLOWED_PREFIX_SEGMENT_TYPES,
                segmentListItemPrefixType: "edit",
            })
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalStore.session.accessGroupId])

    useEffect(() => {
        if (segmentId !== undefined) {
            globalStore.modals.open(
                () => <SegmentFormModal segmentId={Number(segmentId)} />,
                {
                    onClose: () => navigate("/segments/manage-segments"),
                },
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const header = useMemo(
        () => ({
            header: t`navigation.segments`,
            breadcrumbs: [t`segments.header`, t`navigation.segments`],
            createOptions: {
                item: t`global.create`,
                onClick: () => {
                    globalStore.modals.open(() => <SegmentFormModal />)
                },
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const listStackStyles: SxProps = useMemo(
        () => ({
            backgroundColor: palette.common.white,
            padding: "16px",
            borderRadius: "8px",
        }),
        [palette.common.white],
    )

    const tableIsLoading = globalStore.loading.is(
        SegmentPickerStore.LoadingKeys.init,
    )

    return (
        <ListPage header={header} loading={tableIsLoading}>
            <Stack sx={listStackStyles}>
                <SegmentPickerSearch
                    listItemPrefixType="edit"
                    hasSelectAllCheckbox={false}
                />
            </Stack>
        </ListPage>
    )
})

export const SegmentsView = () => (
    <StoreProvider Store={SegmentPickerStore}>
        <View />
    </StoreProvider>
)
