import { styled } from "@mui/material"

import { ContactFormReadUnreadStatus } from "src/types/contact-form"

export const StatusReadUnReadDot = styled("span", {
    shouldForwardProp: () => true,
})<{
    status: ContactFormReadUnreadStatus
}>(({ status, theme }) => ({
    height: "8px",
    width: "8px",
    marginLeft: "20px",
    borderRadius: "10000px",
    background: {
        [ContactFormReadUnreadStatus.UnRead]: theme.palette.brand.red.main,
    }[status],
}))
