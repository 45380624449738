import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import {
    CommunityAdminService,
    PreviewService,
    community_CommunityForAdmin,
} from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { environment } from "src/config"
import { range } from "src/lib/array"
import { getAuthorName } from "src/lib/community"

import { persistFiles } from "src/lib/file"
import { FormFields } from "src/lib/form-fields"

import { createLoadingKeys } from "src/lib/loading"
import { reportError, reportUnhandledApiError } from "src/lib/report"

interface IFormFields {
    id: number | null
    authorId?: string | null
    text: string
    communityIds: number[]
    images: (IFile | null)[]
    pin: boolean
    accessType: string
}

interface ICommunity {
    id: number
    name: string
}

export interface IAuthor {
    // `id` is a unique key for this author within Manager. It's not the id for
    // the author in the backend.
    id: string
    name: string
}

const MAX_IMAGES = 3

const flutterBaseUrl = environment.FLUTTER_BASE_URL

export class CommunityPostDetailStore {
    static Context = React.createContext<CommunityPostDetailStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    form = new FormFields<IFormFields>({
        id: null,
        authorId: null,
        text: "",
        communityIds: [],
        images: new Array(MAX_IMAGES).fill(null),
        pin: false,
        accessType: "",
    })

    authors: IAuthor[] = []
    communities: ICommunity[] = []
    initialized = false

    get isEditing() {
        return this.form.get("id") != null
    }

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => CommunityPostDetailStore.LoadingKeys.init)
    public async init(authors: IAuthor[], id?: number) {
        const communities = await this.loadCommunities()
        this.setCommunities(communities)

        if (id != null) {
            const post = await CommunityAdminService.getV1AdminCommunityPost1({
                postId: id,
            })
            this.setAuthors([{ id: "current", name: getAuthorName(post) }])
            this.form.init({
                id,
                text: post.text ?? "",
                communityIds:
                    post.community_id != null ? [post.community_id] : [],
                images: this.imageUrlsToPersistedFiles(post.image_urls ?? []),
                pin: post.pinned ?? false,
                accessType: post.access_type ?? "",
            })
        } else {
            this.setAuthors(authors)
            this.form.init({
                id: null,
                text: "",
                communityIds: [],
                images: new Array(MAX_IMAGES).fill(null),
                pin: false,
                accessType: "",
            })
        }
        this.setInitialAuthor()

        this.setInitialized()
    }

    @loads(() => CommunityPostDetailStore.LoadingKeys.submit)
    public async submit() {
        this.validate()
        if (this.form.hasErrors()) {
            return
        }

        try {
            await this.form.catchErrors(async () => {
                const data = this.form.data
                if (data.id == null) {
                    await CommunityAdminService.postV1AdminCommunityPost({
                        request: {
                            author_name: this.getAuthorName(),
                            community_ids: data.communityIds,
                            image_urls: await this.getImagesAsPersistedUrls(),
                            pinned: data.pin,
                            text: data.text,
                        },
                    })

                    Channel.send({
                        name: "repository/updated",
                        payload: {
                            repository: "community-posts-and-comments",
                            action: "create",
                        },
                    })
                } else {
                    await CommunityAdminService.patchV1AdminCommunityPost({
                        postId: data.id,
                        request: {
                            // Even though it's not possible to change the
                            // author in the UI we need to add the author name
                            // to the payload otherwise it will be emptied.
                            author_name: this.getAuthorName(),
                            image_urls: await this.getImagesAsPersistedUrls(),
                            pinned: data.pin,
                            text: data.text,
                        },
                    })
                    Channel.send({
                        name: "repository/updated",
                        payload: {
                            repository: "community-posts-and-comments",
                            action: "update",
                        },
                    })
                }
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    public setImageAtIndex(image: IFile | null, index: number) {
        this.form.data.images[index] = image
    }

    public deselectCommunity(id: number) {
        const communities = this.form.get("communityIds")
        this.form.set(
            "communityIds",
            communities.filter((cid) => cid !== id),
        )
    }

    public getAuthorNameFromForm() {
        const id = this.form.get("authorId")
        const author = this.authors.find((author) => author.id === id)
        return author?.name
    }

    public getDetailsPreviewUrl = async () => {
        const detailsPreviewUrl = flutterBaseUrl + "community-preview?base64="
        const data = this.form.data

        const imageUrls = await this.getImagesAsPersistedUrls()

        try {
            const url = await PreviewService.postV1PreviewCommunityPost({
                request: {
                    author_name: this.getAuthorNameFromForm(),
                    category_id: 0,
                    community_ids: [...data.communityIds],
                    image_urls: imageUrls,
                    pinned: false,
                    text: data.text,
                },
            })

            return `${detailsPreviewUrl}${encodeURIComponent(url)}`
        } catch (error) {
            return ""
        }
    }

    private async loadCommunities() {
        try {
            return await CommunityAdminService.getV1AdminCommunity()
        } catch (e) {
            reportUnhandledApiError(e)
            return []
        }
    }

    private setCommunities(communities: community_CommunityForAdmin[]) {
        this.communities = communities.map((community) => ({
            id: community.community_id ?? -1,
            // Disable this rule here as we want to use `name` if
            // `internal_name` is either null or an empty string.
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            name: community.internal_name || community.name || "",
        }))
    }

    private setInitialized() {
        this.initialized = true
    }

    private async getImagesAsPersistedUrls(): Promise<string[]> {
        try {
            const images = this.form
                .get("images")
                .filter(
                    (image): image is Exclude<typeof image, null> =>
                        image != null,
                )
            const persistedImages = await persistFiles(images, "image")
            return persistedImages.map((image) => image.url)
        } catch (e) {
            reportError(
                `community-post-detail-modal.error-failed-to-persist-files`,
                e,
            )
            throw e
        }
    }

    private setAuthors(authors: IAuthor[]) {
        this.authors = authors
    }

    private getInitialAuthor() {
        if (this.authors.length > 0) {
            return this.authors[0].id
        }
        return null
    }

    private setInitialAuthor() {
        //get local stored author
        const localAuthorId = this.getAuthorFromLocalStore()
        //validate if the stored author is pressent in the author list
        const authorExist = this.authors.find(
            (author) => author.id === localAuthorId,
        )

        if (authorExist !== undefined && localAuthorId != null) {
            this.form.set("authorId", localAuthorId)
        } else if (authorExist === undefined || this.authors.length > 0) {
            this.setAuthorIdToLocalStore(this.authors[0].id)
            this.form.set("authorId", this.authors[0].id)
        }
    }

    public getAuthorName() {
        const id = this.form.get("authorId")
        const author = this.authors.find((author) => author.id === id)
        return author?.name
    }

    private imageUrlsToPersistedFiles(
        urls: string[],
    ): (IPersistedFile | null)[] {
        const files: (IPersistedFile | null)[] = urls.map(
            (url): IPersistedFile => ({
                type: "image",
                name: url,
                url,
            }),
        )
        for (const _ in range(Math.max(0, MAX_IMAGES - files.length))) {
            files.push(null)
        }
        return files
    }

    private validate() {
        this.form.clearErrors()

        const data = this.form.data
        if (data.communityIds.length === 0) {
            this.form.setError(
                "communityIds",
                t`community-post-detail-modal.error-must-select-atleast-one-community`,
            )
        }

        if (data.text === "") {
            this.form.setError("text", t`errors.required`)
        }
    }

    private getAuthorFromLocalStore() {
        return window.localStorage.getItem("authorId")
    }

    public setAuthorIdToLocalStore(authorId: string) {
        window.localStorage.setItem("authorId", authorId)
    }
}
