import {
    Alert,
    Box,
    Button,
    Stack,
    useMediaQuery,
    useTheme,
    IconButton,
} from "@mui/material"
import { observer } from "mobx-react"
import { useEffect, useMemo } from "react"

import { t } from "@lingui/macro"

import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined"

import { AuthorSelect } from "./Author"

import { PostWrapper, HeaderWrapper, AuthorWrapper } from "./styled"

import { Icon } from "src/components/icons"

import { Preview } from "src/components/icons/Preview"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { ModalHeader } from "src/components/ModalHeader"
import { CommentForm } from "src/modals/view-community-post/CommentForm"
import { Head } from "src/modals/view-community-post/Head"
import { Post } from "src/modals/view-community-post/Post"

import {
    IAuthor,
    ViewCommunityPostStore,
} from "src/modals/view-community-post/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { AvyPreview } from "src/components/Preview"

interface IProps {
    id: number
    allPostIds?: number[]
}

const View = observer((props: IProps) => {
    const { id, allPostIds } = props
    const store = useStore(ViewCommunityPostStore)
    const gstore = useStore(GlobalStore)
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const authors: IAuthor[] = useMemo(() => {
        const authors: IAuthor[] = [
            { id: "self", name: gstore.session.user?.name ?? "" },
        ]

        if (gstore.session.hasAccessToModule("community_author_name")) {
            const propertyOwners = gstore.session.propertyOwners
            for (const propertyOwner of propertyOwners) {
                authors.push({
                    id: `po-${propertyOwner.id}`,
                    name: propertyOwner.legalName ?? "",
                })
            }

            const legalEntities = propertyOwners.flatMap(
                (propertyOwner) => propertyOwner.legalEntities ?? [],
            )

            for (const legalEntity of legalEntities) {
                authors.push({
                    id: `le-${legalEntity.id}`,
                    name: legalEntity.legalName ?? "",
                })
            }
        }

        return authors
    }, [gstore.session])

    useEffect(() => {
        ;(async () => {
            store.setNavigationData(allPostIds, id)
            await store.init(id, authors)
        })()
    }, [store, id, authors, allPostIds])

    if (
        gstore.loading.is(ViewCommunityPostStore.LoadingKeys.init) ||
        !store.initialized ||
        gstore.loading.is(ViewCommunityPostStore.LoadingKeys["block-unblock"])
    ) {
        return <CenteredSpinner />
    }

    const handleActions = async (action: "previous" | "next") => {
        await store.navigateToPost(action)
        gstore.modals.updatePreviewContent(() => (
            <AvyPreview details={store.getDetailsPreviewUrl} />
        ))
    }

    const onPreviewClick = () => {
        gstore.modals.togglePreview(() => (
            <AvyPreview details={store.getDetailsPreviewUrl} />
        ))
    }

    return (
        <Box display="flex" flexDirection="column" height="100%">
            <HeaderWrapper smallScreen={smallScreen}>
                <ModalHeader />
                <Stack direction="row" spacing={1}>
                    <Button
                        startIcon={
                            <Icon
                                icon={<Preview />}
                                size={20}
                                color={theme.palette.info.main}
                            />
                        }
                        disabled={false}
                        variant="contained"
                        color="info"
                        onClick={onPreviewClick}
                        data-testid="preview-button"
                    >
                        {t`global.preview-button`}
                    </Button>
                    {gstore.session.hasAccessToModule(
                        "community_author_name",
                    ) && (
                        <AuthorWrapper smallScreen={smallScreen}>
                            <AuthorSelect />
                        </AuthorWrapper>
                    )}
                </Stack>
            </HeaderWrapper>
            {allPostIds != null && allPostIds.length > 1 ? (
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent={"space-between"}
                    sx={{ pt: 1 }}
                >
                    <IconButton
                        aria-label="Preview"
                        disabled={!Boolean(store.goPrevious())}
                        title={t`chatbot-view.modal.preview-conversation`}
                        onClick={() => handleActions("previous")}
                    >
                        <ArrowBackOutlinedIcon />
                    </IconButton>
                    <IconButton
                        aria-label="Next"
                        disabled={!Boolean(store.goNext())}
                        title={t`chatbot-view.modal.next-conversation`}
                        onClick={() => handleActions("next")}
                    >
                        <ArrowForwardOutlinedIcon />
                    </IconButton>
                </Stack>
            ) : null}
            {Boolean(store.commentForm.error("generic")) && (
                <Alert severity="error">
                    {store.commentForm.error("generic")}
                </Alert>
            )}
            <PostWrapper>
                <Head />
                <Post />
                <CommentForm />
            </PostWrapper>
        </Box>
    )
})

export const ViewCommunityPostModal = observer((props: IProps) => (
    <StoreProvider Store={ViewCommunityPostStore}>
        <View {...props} />
    </StoreProvider>
))
