import { t } from "@lingui/macro"
import { Checkbox, useTheme } from "@mui/material"
import { useCallback } from "react"
import { observer } from "mobx-react"

import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { ICheckBoxPrefixProps } from "src/components/SegmentPicker/components/SegmentItemListPrefix/types"

export const SegmentCheckboxPrefix = observer(
    ({ segment }: ICheckBoxPrefixProps) => {
        const store = useStore(SegmentPickerStore)
        const globalStore = useStore(GlobalStore)
        const { palette } = useTheme()

        const handleCheckBoxClick = useCallback(() => {
            if (globalStore.session.selectedParakeyType === "Private") {
                store.selectOneSegment(segment)
            } else {
                store.selectSegment(segment)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [globalStore.session.selectedParakeyType, segment])

        return (
            <Checkbox
                onChange={(_, checked) =>
                    checked
                        ? handleCheckBoxClick()
                        : store.deselectSegment(segment)
                }
                checked={store.hasSelectedSegment(segment)}
                title={
                    store.hasSelectedSegment(segment)
                        ? t({
                              id: "segment-picker.available.deselect-segment",
                              values: { name: segment.name },
                          })
                        : t({
                              id: "segment-picker.available.select-segment",
                              values: { name: segment.name },
                          })
                }
                sx={{
                    background: palette.common.white,
                    borderRadius: 0,
                    padding: "4px",
                }}
                data-testid="asdfa"
            />
        )
    },
)
