import { IGenericAutoCompleteTextFieldValue } from "src/types/auto-complete-text-field"
import { IContentItemsConfigTypeOption } from "src/views/content-items/types/content-items"

const configTypeItemsOptionList: IContentItemsConfigTypeOption = {
    move_in_guide: "Move in guide",
    overview: "Overview",
    navigation: "Navigation",
    spotlight: "Spotlight",
    move_in_wizard: "Move in wizard",
}

export const configTypeItemsOptionListArray = Object.keys(
    configTypeItemsOptionList,
).reduce<IGenericAutoCompleteTextFieldValue[]>((acc, key) => {
    acc.push({
        id: key,
        name: configTypeItemsOptionList[
            key as keyof IContentItemsConfigTypeOption
        ],
    })
    return acc
}, [])
