import { t } from "@lingui/macro"
import React from "react"

import { createLoadingKeys } from "src/lib/loading"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { Pagination } from "src/lib/pagination"
import {
    FeaturesAdminService,
    config_FeatureConfigForList,
    config_GetFeatureConfigListRequest,
} from "src/api"
import { IFeatureConfigBrandingItem } from "src/views/feature-config-branding/types"
import { loads } from "src/channel/utils"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { CustomDataHelper } from "src/api/_custom/services/DataHelper"
import { reportError } from "src/lib/report"

import { Channel } from "src/channel"

export class FeatureConfigBrandingStore implements IDisposable {
    static Context = React.createContext<FeatureConfigBrandingStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("init", "loading")

    private repositoryUpdatesListenerDisposer?: () => void

    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    _segments: number[] = []

    featureConfigBrandingItems = new Pagination(
        async (query) => {
            const request: config_GetFeatureConfigListRequest = {
                page_number: query.page,
                page_size: query.pageSize,
                filter: {
                    ...query.advanceQuery,
                    items: [
                        ...(query.advanceQuery?.items ?? []),
                        {
                            field: "config_type",
                            operator: "hasKey",
                            value: "branding",
                        },
                    ],
                },
                ...CustomDataHelper.PrepareVisibilityData(this),
            }

            const response =
                await FeaturesAdminService.postV2AdminFeatureConfigList({
                    request,
                })

            const items: IFeatureConfigBrandingItem[] =
                response.configs?.map((item: config_FeatureConfigForList) => ({
                    id: item.feature_config_id ?? 0,
                    feature_config_id: item.feature_config_id ?? 0,
                    access_group_id: item.access_group_id ?? 0,
                    access_type: item.access_type ?? "NONE",
                    config: item.config ?? {},
                    name: item.name ?? "",
                    segment_ids: item.segment_ids ?? [],
                    segment_names: item.segment_names ?? [],
                })) ?? []

            return {
                items,
                count: response.configs?.length ?? 0,
            }
        },
        { loadingKey: FeatureConfigBrandingStore.LoadingKeys.loading },
    )

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    get segments() {
        return this._segments
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }

    async loadSegments(segments: number[]) {
        await this.setSegments(segments)
        await this.featureConfigBrandingItems.loadInitialPage()
    }

    private setAccessGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private listenToRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "feature-config-branding"
                ) {
                    await this.featureConfigBrandingItems.reload()
                }
            },
        )
    }

    @loads(() => FeatureConfigBrandingStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.listenToRepositoryUpdated()
        this.setAccessGroupId(accessGroupId)
        await this.featureConfigBrandingItems.loadInitialPage(advanceQuery)
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.featureConfigBrandingItems.loadAdvanceQuery(advanceQuery)
    }

    deleteItem = async (configId: string) => {
        try {
            await FeaturesAdminService.deleteV2AdminFeatureConfig({
                configId,
            })
        } catch (error) {
            reportError(t`global.failed-to-delete-item`, error)
        } finally {
            await this.featureConfigBrandingItems.reload()
        }
    }
}
