import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { CasesViewStore } from "./store"

import { StatusReadUnReadDot } from "./styled"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { ListPage } from "src/components/ListPage"
import { IStatusValueOptions } from "src/components/StatusChip"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { NoWrap } from "src/components/Table/styled"
import { Time } from "src/components/Time"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { reportError } from "src/lib/report"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { Repository } from "src/types/channel"
import { FilterModel, IColumn, SortModel } from "src/types/data-grid-pro"
import { CaseStatusChip } from "src/views/cases-v2/components/CaseStatusChip"
import { ICaseItem } from "src/views/cases-v2/type"

import { CaseDetailModalView } from "src/modals/cases-v2-detail"
import { StatusCasesV2 } from "src/types/status-casesV2"
import { RowCellContainer } from "src/components/RowCellContainer"

const repository: Repository = "cases-v2"

const View = observer(() => {
    const store = useStore(CasesViewStore)
    const globalStore = useStore(GlobalStore)

    const statusValueOptions: IStatusValueOptions[] = [
        {
            label: t`cases-view.forms-tab.status-chip-created-status`,
            value: StatusCasesV2.Created,
        },
        {
            label: t`cases-view.forms-tab.status-chip-done-status`,
            value: StatusCasesV2.Done,
        },
        {
            label: t`cases-view.forms-tab.status-chip-inprogress-status`,
            value: StatusCasesV2.InProgress,
        },
        {
            label: t`cases-view.forms-tab.status-chip-externally-handled-status`,
            value: StatusCasesV2.ExternallyHandled,
        },
    ]

    const columns: IColumn<ICaseItem>[] = [
        {
            field: "read",
            headerName: t`cases-view.forms-tab.read-header`,
            filterable: false,
            sortable: false,
            flex: 0.5,
            renderCell: (params) =>
                params.value === false && (
                    <StatusReadUnReadDot status={params.value.toString()} />
                ),
        },
        {
            field: "status",
            headerName: t`cases-view.forms-tab.status-header`,
            renderCell: (params) => (
                <NoWrap>
                    <CaseStatusChip status={params.value} />
                </NoWrap>
            ),
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions: statusValueOptions,
            minWidth: 120,
        },
        {
            field: "title",
            headerName: t`cases-view.forms-tab.title-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            minWidth: 150,
            flex: 2,
        },
        {
            field: "object_address",
            headerName: t`cases-view.forms-tab.address-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            minWidth: 160,
            flex: 2,
        },
        {
            field: "object_id",
            headerName: t`cases-view.forms-tab.object-id-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
        },
        {
            field: "tenant_id",
            headerName: t`cases-view.forms-tab.tenant-id-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "number",
        },
        {
            field: "type_name",
            headerName: t`cases-view.forms-tab.type-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            minWidth: 140,
            flex: 1.5,
        },
        {
            field: "property_object_id",
            headerName: t`cases-view.forms-tab.property-object-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            sortable: false,
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions: store.assignees,
            minWidth: 110,
            flex: 1.5,
        },
        {
            field: "updated_at",
            headerName: t`cases-view.forms-tab.updated-header`,
            renderCell: (params) => <Time date={params.value} />,
            type: "date",
            minWidth: 110,
            flex: 1.5,
        },
        {
            field: "created_at",
            headerName: t`cases-view.forms-tab.created-header`,
            renderCell: (params) => <Time date={params.value} />,
            type: "date",
            minWidth: 110,
            flex: 1.5,
        },
    ]

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            globalStore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            globalStore.session.dataGridSortModel,
        )
        return { sort, filter }
    }, [
        globalStore.session.dataGridSortModel,
        globalStore.session.dataGridFilterModel,
    ])

    useEffect(() => {
        ;(async () =>
            await store.init(globalStore.session.accessGroupId, advanceQuery))()

        return () => store.dispose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, globalStore.session.accessGroupId])

    useEffect(() => {
        ;(async () => {
            try {
                await store.getAssignees()
            } catch (error) {
                reportError(t`cases-view.get-assignees-list-error`, error)
            }
        })()

        return () => store.dispose()
    }, [store])

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                globalStore.session.dataGridSortModel,
            )

            await store.query({ sort, filter: model })
        },
        [store, globalStore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                globalStore.session.dataGridFilterModel,
            )

            await store.query({ filter, sort: model })
        },
        [store, globalStore.session.dataGridFilterModel],
    )

    const openDetailModalHandler = useCallback(
        (item) => {
            trackModuleEvent(" Cases | Detailed View", {
                [MixpanelProperties.ItemID]: item.id,
                [MixpanelProperties.AccessGroupName]:
                    globalStore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    globalStore.session.accessGroup?.id,
            })
            globalStore.modals.open(() => <CaseDetailModalView id={item.id} />)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalStore.modals],
    )

    return (
        <ListPage
            header={{
                header: t`cases-view.header`,
                breadcrumbs: [t`cases-view.cases-breadcrumb`],
            }}
            loading={
                globalStore.loading.is(CasesViewStore.LoadingKeys.init) ||
                globalStore.loading.is(
                    CasesViewStore.LoadingKeys.getAssignees,
                ) ||
                !store.caseLists.meta.initialized
            }
        >
            <DataGridProTable
                paginator={store.caseLists}
                data={store.caseLists.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                }}
                onRowClickEvent={openDetailModalHandler}
                repository={repository}
                loading={globalStore.loading.is(
                    CasesViewStore.LoadingKeys.loading,
                )}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const CasesView = () => (
    <StoreProvider Store={CasesViewStore}>
        <View />
    </StoreProvider>
)
