import { Locale } from "src/locales/locale"

const commonConfigurableLanguage = `${Locale.Swedish}, ${Locale.English}`

export const DEFAULT_CONFIGURABLE_LANGUAGE_VALUE = `${commonConfigurableLanguage}, ${Locale.Finnish}`
export const CONFIGURABLE_LANGUAGE_VALUES = {
    "- None -": "",
    [commonConfigurableLanguage]: commonConfigurableLanguage,
    [`${Locale.Finnish}, ${commonConfigurableLanguage}`]: `${Locale.Finnish}, ${commonConfigurableLanguage}`,
}
