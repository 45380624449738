import { styled } from "@mui/material"

import { ReadUnreadStatus } from "src/types/status"

export const StatusReadUnReadDot = styled("span", {
    shouldForwardProp: () => true,
})<{
    status: ReadUnreadStatus
}>(({ status, theme }) => ({
    height: "8px",
    width: "8px",
    marginLeft: "20px",
    borderRadius: "10000px",
    background: {
        [ReadUnreadStatus.UnRead]: theme.palette.brand.red.main,
    }[status],
}))
