import React from "react"
import { makeAutoObservable } from "mobx"

import { Pagination } from "src/lib/pagination"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"
import { parseDate } from "src/lib/date"
import { CommunityAdminService } from "src/api"
import { Channel } from "src/channel"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { reportUnhandledApiError } from "src/lib/report"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { IPostItem } from "src/views/community-posts/type"

export class CommunityPostsStore implements IDisposable {
    static Context = React.createContext<CommunityPostsStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading", "delete-post")

    private repositoryChangeListenerDisposer?: () => void
    private accessGroupId = DEFAULT_ACCESS_GROUP.id
    private _allPostIds: number[] = []

    posts = new Pagination(
        async (params) => {
            const response =
                await CommunityAdminService.getV1AdminCommunityPost({
                    pageNumber: params.page,
                    pageSize: params.pageSize,
                    accessGroupId: this.getAccessGroupId(),
                    filter: JSON.stringify(params.advanceQuery),
                })

            const items: IPostItem[] = (response.posts ?? []).map((post) => ({
                id: post.post_id ?? -1,
                text: post.text ?? "-",
                authorObjectType: post.author?.object_type ?? "none",
                authorObjectId: post.author?.object_id ?? -1,
                "author.author_name": post.author?.author_name ?? "-",
                likeCount: post.likes?.length ?? 0,
                commentCount: post.comments_count ?? 0,
                access_type: post.access_type ?? "",
                created_at: parseDate(post.created_date ?? ""),
                author_name: post.author_name ?? "-",
                community_name: this.getStringDefaultValue(post.community_name),
                internal_apartment_id: post.internal_apartment_id ?? "-",
                apartment_no: post.apartment_no ?? "-",
                updated_at: parseDate(post?.updated_at ?? ""),
            }))

            this.setAllPostIds(items.map((item) => item.id))

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        {
            loadingKey: CommunityPostsStore.LoadingKeys.loading,
        },
    )

    constructor() {
        makeAutoObservable(this)
    }

    get allPostIds() {
        return this._allPostIds
    }

    private setAllPostIds(allPostIds: number[]) {
        this._allPostIds = allPostIds
    }

    @loads(() => CommunityPostsStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery: IAdvanceQueryModel) {
        this.setAccessGroupId(accessGroupId)
        await this.posts.loadInitialPage(advanceQuery)
        this.listenToRepositoryChanges()
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.posts.loadAdvanceQuery(advanceQuery)
    }

    @loads(() => CommunityPostsStore.LoadingKeys["delete-post"])
    async deletePost(id: number) {
        try {
            await CommunityAdminService.deleteV1AdminCommunityPost({
                postId: id,
            })

            Channel.send({
                name: "repository/updated",
                payload: { repository: "community-posts", action: "delete" },
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    dispose() {
        this.repositoryChangeListenerDisposer?.()
    }

    canEditPost(postId: number, adminId?: number) {
        const post = this.posts.items.find((item) => item.id === postId)
        if (post == null) {
            return false
        }

        return (
            post.authorObjectType === "admin_user" &&
            post.authorObjectId === adminId
        )
    }

    private setAccessGroupId(accessGroupId: number) {
        this.accessGroupId = accessGroupId
    }

    private getAccessGroupId() {
        return this.accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this.accessGroupId
            : undefined
    }

    private listenToRepositoryChanges() {
        this.repositoryChangeListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "community-posts"
                ) {
                    if (
                        event.payload.action === "update" ||
                        event.payload.action === "delete"
                    ) {
                        await this.posts.reload()
                    } else {
                        await this.posts.loadInitialPage()
                    }
                }
            },
        )
    }

    private getStringDefaultValue(value?: string) {
        return (value !== null || value !== undefined) && value?.length !== 0
            ? value
            : "-"
    }
}
