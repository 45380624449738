import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { ContactFormsViewStore } from "./store"

import { StatusReadUnReadDot } from "./styled"

import { ListPage } from "src/components/ListPage"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { NoWrap } from "src/components/Table/styled"
import { Time } from "src/components/Time"
import { ContactFormDetailModalView } from "src/modals/contact-form-detail"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { FilterModel, IColumn, SortModel } from "src/types/data-grid-pro"
import { ContactFormImplementedChip } from "src/views/contact-forms/ContactFormImplementedChip"
import { ContactFormStatusChip } from "src/views/contact-forms/ContactFormStatusChip"
import { IContactFormItem } from "src/views/contact-forms/type"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { Repository } from "src/types/channel"
import { Status } from "src/types/status"
import { IStatusValueOptions } from "src/components/StatusChip"
import { reportError } from "src/lib/report"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { RowCellContainer } from "src/components/RowCellContainer"

const repository: Repository = "contact-forms"

const View = observer(() => {
    const store = useStore(ContactFormsViewStore)
    const gstore = useStore(GlobalStore)

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            gstore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            gstore.session.dataGridSortModel,
        )
        return { sort, filter }
    }, [gstore.session.dataGridSortModel, gstore.session.dataGridFilterModel])

    useEffect(() => {
        ;(async () =>
            await store.init(gstore.session.accessGroupId, advanceQuery))()

        return () => store.dispose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, gstore.session.accessGroupId])

    useEffect(() => {
        ;(async () => {
            try {
                await store.getAssignees()
            } catch (error) {
                reportError(
                    t`contact-forms-view.get-assignees-list-error`,
                    error,
                )
            }
        })()

        return () => store.dispose()
    }, [store])

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                gstore.session.dataGridSortModel,
            )

            await store.query({ sort, filter: model })
        },
        [store, gstore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                gstore.session.dataGridFilterModel,
            )

            await store.query({ filter, sort: model })
        },
        [store, gstore.session.dataGridFilterModel],
    )

    const openDetailModalHandler = useCallback(
        (item) => {
            trackModuleEvent(" Cases | Detailed View", {
                [MixpanelProperties.ItemID]: item.id,
                [MixpanelProperties.AccessGroupName]:
                    gstore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    gstore.session.accessGroup?.id,
            })
            gstore.modals.open(() => (
                <ContactFormDetailModalView id={item.id} />
            ))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gstore.modals],
    )

    const statusValueOptions: IStatusValueOptions[] = [
        {
            label: t`contact-forms-view.forms-tab.status-chip-done-status`,
            value: Status.Done,
        },
        {
            label: t`contact-forms-view.forms-tab.status-chip-inprogress-status`,
            value: Status.InProgress,
        },
        {
            label: t`contact-forms-view.forms-tab.status-chip-sent-status`,
            value: Status.Sent,
        },
        {
            label: t`contact-forms-view.forms-tab.status-chip-sentfallback-status`,
            value: Status.SentFallback,
        },
    ]

    const columns: IColumn<IContactFormItem>[] = [
        {
            field: "read",
            headerName: t`contact-forms-view.forms-tab.read-header`,
            filterable: false,
            sortable: false,
            flex: 0.5,
            renderCell: (params) =>
                params.value === false && (
                    <StatusReadUnReadDot status={params.value.toString()} />
                ),
        },
        {
            field: "status",
            headerName: t`contact-forms-view.forms-tab.status-header`,
            renderCell: (params) => (
                <NoWrap>
                    <ContactFormStatusChip status={params.value} />
                </NoWrap>
            ),
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions: statusValueOptions,
            minWidth: 150,
        },
        {
            field: "title",
            headerName: t`contact-forms-view.forms-tab.title-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            minWidth: 150,
            flex: 2,
        },
        {
            field: "object_address",
            headerName: t`contact-forms-view.forms-tab.address-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            minWidth: 150,
            flex: 2,
        },
        {
            field: "object_id",
            headerName: t`contact-forms-view.forms-tab.object-id-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
        },
        {
            field: "tenant_id",
            headerName: t`contact-forms-view.forms-tab.tenant-id-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "number",
        },
        {
            field: "type_name",
            headerName: t`contact-forms-view.forms-tab.type-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            minWidth: 110,
            flex: 1.5,
        },
        {
            field: "assigned_to",
            headerName: t`contact-forms-view.forms-tab.assignee-header`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            sortable: false,
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions: store.assignees,
            minWidth: 110,
            flex: 1.5,
        },
        {
            field: "implemented",
            headerName: t`contact-forms-view.forms-tab.implemented-header`,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <RowCellContainer>
                    <ContactFormImplementedChip date={params.value} />
                </RowCellContainer>
            ),
        },
        {
            field: "updated_at",
            headerName: t`contact-forms-view.forms-tab.updated-header`,
            renderCell: (params) => <Time date={params.value} />,
            type: "date",
            minWidth: 110,
            flex: 1.5,
        },
        {
            field: "created_at",
            headerName: t`contact-forms-view.forms-tab.created-header`,
            renderCell: (params) => <Time date={params.value} />,
            type: "date",
            minWidth: 110,
            flex: 1.5,
        },
    ]
    return (
        <ListPage
            header={{
                header: t`contact-forms-view.header`,
                breadcrumbs: [t`contact-forms-view.contact-forms-breadcrumb`],
            }}
            loading={
                gstore.loading.is(ContactFormsViewStore.LoadingKeys.init) ||
                gstore.loading.is(
                    ContactFormsViewStore.LoadingKeys.getAssignees,
                ) ||
                !store.contactForms.meta.initialized
            }
        >
            <DataGridProTable
                paginator={store.contactForms}
                data={store.contactForms.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "server",
                }}
                onRowClickEvent={openDetailModalHandler}
                repository={repository}
                loading={gstore.loading.is(
                    ContactFormsViewStore.LoadingKeys.loading,
                )}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const ContactFormsView = observer(() => (
    <StoreProvider Store={ContactFormsViewStore}>
        <View />
    </StoreProvider>
))
