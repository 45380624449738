import { avy_api_pkg_segment_SegmentType } from "src/api"
import { ISegmentItemListPrefixType } from "src/components/SegmentPicker/components/SegmentItemListPrefix/types"

export enum SegmentTypeGroup {
    Standard,
    Custom,
}

export type ISegmentType = ISegmentForSearch["type"]

export type ISegmentTypeGroupMap = {
    [key in SegmentTypeGroup]: ISegmentType[]
}

export type ISegmentMap = { [key: number]: ISegmentForSearch }
export type ISegmentTypeIndexMap = { [key: string]: number }
export interface ISegmentPickerInitParams {
    accessGroupId: number | null
    initialSegmentIds: number[]
    allowedSegmentTypes?: ISegmentForSearch["type"][]
    publishingLevel?: avy_api_pkg_segment_SegmentType
    segmentListItemPrefixType?: ISegmentItemListPrefixType
    allowedPrefixSegmentTypes?: ISegmentType[]
    onPressSegmentEdit?: () => void
}

export interface ISegmentFilter {
    segmentType: ISegmentType
    parent: ISegmentForSearch | null
    query: string | null
}
