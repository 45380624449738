import { t } from "@lingui/macro"
import { Stack, Typography, Button, FormHelperText } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { observer } from "mobx-react"
import { useCallback, useMemo, useEffect } from "react"

import { FormPanel } from "src/components/FormPanel"
import { SegmentPickerModal } from "src/modals/segment-picker"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { SegmentFormStore } from "src/modals/segment-form/store"
import { SegmentHierarchyListItem } from "src/modals/segment-form/components/SegmentHierarchyListItem/index"
import { SegmentPickerStore } from "src/components/SegmentPicker/store"

export const SegmentsHierarchySection = observer(() => {
    const gstore = useStore(GlobalStore)
    const store = useStore(SegmentFormStore)
    const segmentPickerStore = useStore(SegmentPickerStore)

    const handleChange = useCallback(async (segmentIds) => {
        await store.updateParentSegmentAndFormFields(segmentIds)
        gstore.modals.pop()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initialSegmentIds = useMemo(() => {
        return store.parentSegment != null ? [store.parentSegment.id] : []
    }, [store.parentSegment])

    const onCloseSegmentPicker = useCallback(() => {
        segmentPickerStore.setInitialSegmentIds([])
        gstore.modals.pop()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSegmentPickerOpen = useCallback(() => {
        // Pre-initialize the segmentPickerStore with the parent segment
        if (store.parentSegment != null) {
            segmentPickerStore.setInitialSegmentIds([store.parentSegment.id])
        }

        gstore.modals.open(
            () => (
                <SegmentPickerModal
                    initialSegmentIds={initialSegmentIds}
                    accessGroupId={gstore.session.accessGroupId}
                    onChange={handleChange}
                    onClose={onCloseSegmentPicker}
                    segmentListItemPrefixType="radio"
                />
            ),
            { variant: "slide-up-w995" },
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        initialSegmentIds,
        store.parentSegment,
        gstore.session.accessGroupId,
        segmentPickerStore.allowedSegmentTypes,
    ])

    // Ensure segmentPickerStore is initialized with parent segment when it changes
    useEffect(() => {
        if (store.parentSegment != null) {
            segmentPickerStore.setInitialSegmentIds([store.parentSegment.id])
        }
    }, [store.parentSegment, segmentPickerStore])

    const segmentTypeError = useMemo(() => {
        return store.formFields.error("parent_segment_id")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.formFields.error("parent_segment_id")])

    return (
        <FormPanel
            sections={[
                {
                    header: t`segment-form-modal.hierarchy`,
                    content: (
                        <Stack spacing={2}>
                            <Typography variant="body2" color="text.secondary">
                                {t`segment-form-modal.section.description`}
                            </Typography>
                            {store.parentSegment != null && (
                                <SegmentHierarchyListItem
                                    {...store.parentSegment}
                                />
                            )}
                            <Button
                                variant="outlined"
                                color="primary"
                                size="medium"
                                startIcon={<AddIcon />}
                                sx={{
                                    backgroundColor: "transparent",
                                    alignSelf: "flex-start",
                                }}
                                onClick={handleSegmentPickerOpen}
                            >
                                {store.parentSegment != null
                                    ? t`global.edit`
                                    : t`global.add`}
                            </Button>
                            {segmentTypeError !== undefined && (
                                <FormHelperText error>
                                    {segmentTypeError}
                                </FormHelperText>
                            )}
                        </Stack>
                    ),
                },
            ]}
        />
    )
})
