import { Stack } from "@mui/material"
import { observer } from "mobx-react"

import { SegmentPickerSelected } from "src/components/SegmentPicker/components/SegmentPickerSelected"
import { SegmentPickerSearch } from "src/components/SegmentPicker/SegmentPickerSearch"
import {
    Divider,
    ModalColumn,
} from "src/components/SegmentPicker/SplitSegmentPicker/styled"
import { ISplitSegmentPickerProps } from "src/components/SegmentPicker/SplitSegmentPicker/types"

export const SplitSegmentPicker = observer(
    (props: ISplitSegmentPickerProps) => {
        return (
            <Stack
                direction="row"
                divider={<Divider />}
                sx={{ height: "100%" }}
            >
                <ModalColumn>
                    <SegmentPickerSearch />
                </ModalColumn>
                <ModalColumn>
                    <SegmentPickerSelected
                        onSave={props.onSave}
                        onClose={props.onClose}
                        initialSegmentIds={props.initialSegmentIds}
                    />
                </ModalColumn>
            </Stack>
        )
    },
)
