import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { MetaDataSection } from "./sections/MetaDataSection"
import { PublishingSection } from "./sections/PublishingSection"
import { IChatbotConfigurationModalViewProps } from "./types/configAgentProps"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { ConfigurableDynamicFormFieldsSection } from "src/components/ConfigurableDynamicFormFields"
import { ConfigurableDynamicFormFieldsStore } from "src/components/ConfigurableDynamicFormFields/store"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { useInitializer } from "src/lib/initializer"
import { ChatbotConfigurationStore } from "src/modals/chatbot-configuration/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"

const View = observer(({ id }: IChatbotConfigurationModalViewProps) => {
    const globalStore = useStore(GlobalStore)
    const store = useStore(ChatbotConfigurationStore)
    const configurableDynamicFormFieldsStore = useStore(
        ConfigurableDynamicFormFieldsStore,
    )

    useCloseConfirmationForForm(
        store.form,
        configurableDynamicFormFieldsStore.form,
    )

    const initialized = useInitializer(async () => {
        await store.init(id, globalStore.session.accessGroupId)
    }, [globalStore.session.accessGroupId])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const validationError = await store.handleSubmit({
                defaultLanguage:
                    globalStore.session.defaultConfigurableLanguage,
                validateConfigurableDynamicFields:
                    configurableDynamicFormFieldsStore.handleValidation,
                getConfigsApiPayload:
                    configurableDynamicFormFieldsStore.getConfigsApiPayload,
            })

            if (validationError && !Boolean(store.form.error("generic"))) {
                globalStore.modals.pop()
            }
        },
        [
            globalStore.session.defaultConfigurableLanguage,
            store,
            configurableDynamicFormFieldsStore.getConfigsApiPayload,
            configurableDynamicFormFieldsStore.handleValidation,
            globalStore.modals,
        ],
    )

    if (!initialized) {
        return <CenteredSpinner height="100vh" />
    }

    const isSaveButtonDisabled =
        (store.form.getIsDirty() === false &&
            configurableDynamicFormFieldsStore.form.getIsDirty() === false) ||
        store.isLoading ||
        store.isReadOnly

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isSaveButtonDisabled}
                >
                    {t`global.save`}
                </Button>
            </ModalHeader>
            {Boolean(store.form.error("generic")) && (
                <Alert severity="error">{store.form.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`chatbot-configuration-detail.meta-data`,
                        content: <MetaDataSection />,
                    },
                ]}
            />
            <ConfigurableDynamicFormFieldsSection
                configDefinition={store.configDefinition}
                configs={store.form.get("configs") ?? {}}
                isReadOnly={store.isReadOnly}
                configType={store.form.get("config_type")}
            />
            <FormPanel
                sections={[
                    {
                        header: t`chatbot-configuration-detail.publishing-settings`,
                        content: <PublishingSection />,
                    },
                ]}
            />
        </Form>
    )
})

export const ChatbotConfigurationModalView = (
    props: IChatbotConfigurationModalViewProps,
) => (
    <StoreProvider Store={ChatbotConfigurationStore}>
        <StoreProvider Store={ConfigurableDynamicFormFieldsStore}>
            <View {...props} />
        </StoreProvider>
    </StoreProvider>
)
