import { Box } from "@mui/material"
import { observer } from "mobx-react"
import { useEffect } from "react"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { ConfigurableSection } from "src/components/ConfigurableDynamicFormFields/components/ConfigurableSection"
import { ConfigurableDynamicFormFieldsStore } from "src/components/ConfigurableDynamicFormFields/store"
import { IConfigurableDynamicFormFieldProps } from "src/components/ConfigurableDynamicFormFields/types/configurable-dynamic-form-fields-props"
import { useInitializer } from "src/lib/initializer"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const ConfigurableDynamicFormFieldsSection = observer(
    ({
        configDefinition,
        configs,
        isReadOnly,
        configType,
    }: IConfigurableDynamicFormFieldProps) => {
        const globalStore = useStore(GlobalStore)
        const store = useStore(ConfigurableDynamicFormFieldsStore)

        const initialized = useInitializer(async () => {
            await store.init(configDefinition, configs, isReadOnly, configType)
        }, [])

        useEffect(() => {
            // When location is updated, update the store variables, but after initialization
            initialized &&
                store.updateLocationAndRelatedFields(
                    configType,
                    configDefinition.definition,
                )
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [configType])

        if (
            !initialized ||
            globalStore.loading.is(
                ConfigurableDynamicFormFieldsStore.LoadingKeys.init,
            )
        ) {
            return <CenteredSpinner height="100vh" />
        }

        return (
            <Box>
                {store.configurableFields?.map((configurableFields, index) => (
                    <ConfigurableSection
                        configurableFields={configurableFields}
                        index={index}
                        key={configurableFields.name}
                    />
                ))}
            </Box>
        )
    },
)
