import { observer } from "mobx-react"
import { useMemo } from "react"

import AssetLibraryPicker from "src/components/AssetLibraryPicker"
import { useStore } from "src/store/lib/useStore"
import { ConfigurableDynamicFormFieldsStore } from "src/components/ConfigurableDynamicFormFields/store"
import { getSectionFieldName } from "src/components/ConfigurableDynamicFormFields/helpers/fieldNameHelpers"
import { IConfigurableDynamicFormFieldsAssetLibraryPickerProps } from "src/components/ConfigurableDynamicFormFields/types/form-field"

export const ConfigurableDynamicFormFieldsAssetLibraryPicker = observer(
    ({
        activeAssetType,
        sectionName,
        field,
    }: IConfigurableDynamicFormFieldsAssetLibraryPickerProps) => {
        const store = useStore(ConfigurableDynamicFormFieldsStore)
        const formFieldKey = useMemo(
            () => getSectionFieldName(sectionName, field.name),
            [field.name, sectionName],
        )

        return (
            <AssetLibraryPicker
                value={store.form.get(formFieldKey) as string}
                onIconDelete={() => store.form.set(formFieldKey, "")}
                onIconChange={(images) =>
                    store.handleIconChange(images, formFieldKey)
                }
                errorMessage={store.form.error(formFieldKey) ?? ""}
                setErrorMessage={(error) =>
                    store.form.setError(formFieldKey, error)
                }
                acceptedFormats={[
                    "image/png",
                    "image/jpeg",
                    "image/jpg",
                    "image/svg+xml",
                ]}
                aspectRatio="1/1"
                disabled={store.isReadOnly}
                activeAssetType={activeAssetType}
            />
        )
    },
)
